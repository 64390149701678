import {
  Card,
  CardContent,
  Chip,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import withStyles from "@mui/styles/withStyles";
import * as _ from "lodash";
import { DivTypography } from "../components/typography";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import {
  Campaign,
  multiGroupBy,
  TableCellSchedule,
} from "../components/task/task-data";
import { TableRowAutoHeight } from "../components/table";
import classNames from "classnames";
import { getDomainUrl } from "../components/task/adcopyHierarchyTask/templates";
import AdCopyPreview from "../components/task/adcopyHierarchyTask/adCopy-hierarchy-preview";
import AdCopyHierarchy from "../components/task/adcopyHierarchyTask/adCopy-hierarchy";
import icons from "../components/icons";
import CustomToolTip from "./customToolTip";
import * as PropTypes from "prop-types";
import task, { IconAndName } from "./task";
import Warning from "@mui/icons-material/Warning";
import { store } from "../reducers/common";
import NumericLabel from "react-pretty-numbers";
import getSymbolFromCurrency from "currency-symbol-map";
import AdCopyGeneration from "../NewInterfacePages/rsaTask/adcopy";
import EastIcon from "@mui/icons-material/East";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import "./task.css";
import React, { Fragment, useState, useEffect } from "react";

import { TaskTypeEnum,addCommas } from "./commonFunctions/commonFunctions";

export function getRenderer(taskType) {
  switch (taskType) {
    case TaskTypeEnum.INCREASE_BID_GEO_LOCATIONS.taskTypeId:
    case TaskTypeEnum.DECREASE_BID_GEO_LOCATIONS.taskTypeId:
    case TaskTypeEnum.INCREASE_BID_GEO_LOCATIONS_PLA.taskTypeId:
    case TaskTypeEnum.DECREASE_BID_GEO_LOCATIONS_PLA.taskTypeId:
      return regionAndBid;
    // return regionAndBid;

    case TaskTypeEnum.SQR_EXACT_NEGATIVE_KEYWORDS.taskTypeId:
    case TaskTypeEnum.ADD_NEGATIVE_KEYWORDS.taskTypeId:
      return withAdgroupsAndKeywords;
    // return keywordWithMatchType;

    case TaskTypeEnum.KEY_BID_INCREASE_LOW_IMPRESSION.taskTypeId:
    case TaskTypeEnum.DECREASE_BID_NON_PERFORM_KEY.taskTypeId:
    case TaskTypeEnum.INCREASE_BID_AOV.taskTypeId:
    case TaskTypeEnum.DECREASE_BID_AOV.taskTypeId:
    case TaskTypeEnum.CHANGE_KEYWORD_BIDS_CUSTOM.taskTypeId:
      return withAdgroupsAndKeywordsOldNewBid;
    // return kwMatchTypeOldNewCPC;

    // case 3:
    case TaskTypeEnum.REMOVE_CONFLICT_NEG_KEY.taskTypeId:
      return withAdgroupsAndKeywords;
    // return keywordWithMatchType;

    case TaskTypeEnum.SQR_EXACT_KEYWORDS.taskTypeId:
    case TaskTypeEnum.CONVERTING_KEY_MATCH_TYPE.taskTypeId:
    case TaskTypeEnum.ADD_KEYWORD_CUSTOM.taskTypeId:

    case TaskTypeEnum.SQR_CONVERTING_SEARCH_QUERIES.taskTypeId:
    case TaskTypeEnum.ADD_NEW_KEYWORD_OPPORTUNITY_ANALYSIS_FRESH_KEYWORD_TASK
      .taskTypeId:
      // return keywordWithMatchTypeURLAndCPC;
      return withAdgroupsAndKeywords;

    case TaskTypeEnum.COST_TAKING_SQ_AS_NEGATIVE.taskTypeId:
    case TaskTypeEnum.PAUSE_NON_PERFORM_KEY.taskTypeId:
    case TaskTypeEnum.PAUSE_BROAD_KEYWORD.taskTypeId:
      // return keywordWithMatchType;
      return withAdgroupsAndKeywords;

    case TaskTypeEnum.PAUSE_ADGRPS_NO_ACTIVE_KEY.taskTypeId:
    case TaskTypeEnum.PAUSE_ADGRPS_NO_ACTIVE_ADCOPY.taskTypeId:
    case TaskTypeEnum.PAUSE_ADGRPS_CONFLICT_PRODUCT_GRPS.taskTypeId:
      // return adgroup;
      return withCampaignsAndAdgroups;
    case TaskTypeEnum.PAUSE_KEY_BROKEN_URLS.taskTypeId:
      // case 3:
      // return keywordWithMatchTypeAndURL;
      return withKeywordsAndMatchType;

    case TaskTypeEnum.PAUSE_ADCOPY_BROKEN_URLS.taskTypeId:
      return withAdgroupsAndKeywords;
    // return urlAndText;
    case TaskTypeEnum.OPTIMIZE_SPEND_MOBILE_DEVICES.taskTypeId:
    case TaskTypeEnum.INCREASE_BID_MOBILE_DEVICES.taskTypeId:
    case TaskTypeEnum.INCREASE_BID_MOBILE_DEVICES_PLA.taskTypeId:
    case TaskTypeEnum.OPTIMIZE_SPEND_MOBILE_DEVICES_PLA.taskTypeId:
      //adgroup related
      // return adgroupBidModifier;
      return adgroupBidModifier;

    case TaskTypeEnum.REMOVE_CONFLICT_CAMPAIGN_NEG_KEY.taskTypeId:
    case TaskTypeEnum.ADD_CAMPAIGN_NEGATIVE.taskTypeId:
      // return keywordWithMatchType;
      return withCampaignsAndAdgroups;

    case TaskTypeEnum.RESTRUCTURE_PRODUCT_GROUP.taskTypeId:
      return productGroupDimCPC;

    case TaskTypeEnum.INCREASE_BID_PRODUCT_GRPS.taskTypeId:
    case TaskTypeEnum.DECREASE_BID_PRODUCT_GRPS.taskTypeId:
      return productGroupDimCPC;

    case TaskTypeEnum.RESTRUCTURE_PRODUCT_GRP_CROSS_SERVING.taskTypeId:
      return addRemoveProductGroups;
    // return addRemoveProductGroups;

    case TaskTypeEnum.NEW_PRODUCT_GRP_OPPORTUNITIES.taskTypeId:
      return productGroupDimCPC;

    case TaskTypeEnum.INCREASE_BUDGET_CUSTOM.taskTypeId:
    case TaskTypeEnum.DECREASE_BUDGET_CUSTOM.taskTypeId:
      return campaignNameTypeBudget;

    case TaskTypeEnum.NEW_SHOPPING_CAMPAIGN.taskTypeId:
    case TaskTypeEnum.NEW_SMART_SHOPPING_CAMPAIGN.taskTypeId:
      return campaignNameBudget;
    // return campaignNameTypeBudget;
    // return campaignNameAdgroupTypeBudget;

    case TaskTypeEnum.HOURLY_BID_MODIFIER.taskTypeId:
      return withSchedule2;
    // return Schedule2;

    case TaskTypeEnum.CAMPAIGN_SITE_LINKS.taskTypeId:
    case TaskTypeEnum.DISABLE_SEARCH_PARTNER.taskTypeId:
      return campaign;

    case TaskTypeEnum.REMOVE_ADGROUP_NEG_KEYWORDS.taskTypeId:
      return withAdgroupsAndKeywords;
    // return keywordWithMatchTypeAndStatus;

    case TaskTypeEnum.REMOVE_CAMPAIGN_NEG_KEYWORDS.taskTypeId:
      return withAdgroupsAndKeywords;
    // return keywordWithMatchTypeAndStatus;
    /*case 45:
            return withAdgroupsAndKeywords;*/
    // return urlAndTextAds;

    case TaskTypeEnum.SETUP_SHOPPING_FEED.taskTypeId:
      return withAdgroupsAndKeywords;
    // return shoppingTask;
    // case 47:
    //     return withAdgroupsAndKeywords;
    // return pauseAdCopyHierarchy;
    case TaskTypeEnum.ADD_ADCOPY.taskTypeId:
      return rsaAdCopyGeneration;
    // return adCopyHierarchy;
    case TaskTypeEnum.ADD_ADCOPY_RECOMMENDATION.taskTypeId:
    case TaskTypeEnum.PAUSE_ADCOPY.taskTypeId:
      return adCopyHierarchy;
    // return addAdCopy;
    /* case 54:
             return withAdgroupsAndKeywords;*/
    // return shoppingTask;
    case TaskTypeEnum.MAXIMUM_CLICKS_POSSIBLE_.taskTypeId:
      // return withAdgroupsAndKeywords;
      // return campaignNameTypeBudget;
      return campaignNameTypeBidAdjustment;
    // return campaignBidStrategyToMaxClicks;

    //Increase Target ROAS for cost taking campaigns
    case TaskTypeEnum.INCREASE_TARGET_ROAS.taskTypeId:
      return IncreaseOrDecreaseTROAS;

    // Decrease Target ROAS to improve performance
    case TaskTypeEnum.DECREASE_TARGET_ROAS.taskTypeId:
      return IncreaseOrDecreaseTROAS;

    //Increase Target CPA to improve performance
    case TaskTypeEnum.INCREASE_TARGET_CPA.taskTypeId:
      return IncreaseOrDecreaseTROAS;

    //Decrease Target CPA for cost taking campaigns
    case TaskTypeEnum.DECREASE_TARGET_CPA.taskTypeId:
      return IncreaseOrDecreaseTROAS;

    //Upgrade Bidding Strategy to Target ROAS
    case TaskTypeEnum.UPGRADE_MANUAL_BID_TO_TARGET_ROAS.taskTypeId:
      return UpgradeBiddingStrategy;

    //Upgrade Bidding Strategy to Target TCPA
    case TaskTypeEnum.UPGRADE_MANUAL_BID_TO_TARGET_CPA.taskTypeId:
      return UpgradeBiddingStrategy;

    default:
      return null;
  }
}

const CustomTableHead = withStyles((theme) => ({
  root: {
    color: theme.palette.text,
    background: "rgba(242, 243, 249, 0.6)",
    border: "1px solid #E3E6F4",
    boxSizing: "border-box",
    borderRadius: "10px 10px 0px 0px",
  },
}))(TableHead);
const CustomFormControlLabelHead = withStyles((theme) => ({
  label: {  
    fontSize: "0.875rem",
  },
}))(FormControlLabel);

function GenericBox({ name, caption, icon, children }) {
  return (
    <Fragment>
      <Paper className={"fullWidth"}>
        {/*<Typography style={{fontSize:10, backgroundColor:blueGrey[100], textAlign:"center"}}>New Keyword</Typography>*/}
        <Grid container style={{ padding: 10 }} alignItems={"center"}>
          <Grid item className={"growable"}>
            <IconAndName icon={icon} name={name} caption={caption} />
          </Grid>
          <Grid item>{children}</Grid>
        </Grid>
      </Paper>
    </Fragment>
  );
}

function IncreaseOrDecreaseTarget({
  taskTypeId,
  taskDataById,
  name,
  caption,
  isActivityReport,
  id,
  toggleSelection,
  currTarget,
  recommendedTarget,
  classes,
}) {
  const [newRecommendedTarget, setNewRecommendedTarget] = useState(
    recommendedTarget
  );
  const [newCurrTarget, setNewCurrTarget] = useState(currTarget);
  const [currTargetText, setCurrTargetText] = useState();
  const [recommendedTargetText, setRecommendedTargetText] = useState();
  const [targetChange, setTargetChange] = useState();

  useEffect(() => {
    if (
      taskTypeId === TaskTypeEnum.INCREASE_TARGET_ROAS.taskTypeId ||
      taskTypeId === TaskTypeEnum.DECREASE_TARGET_ROAS.taskTypeId
    ) {
      setCurrTargetText("Current Target ROAS");
      setRecommendedTargetText("Recommended Target ROAS");
      setNewRecommendedTarget(
        (prevTarget) => addCommas(Math.floor(prevTarget * 100)) + "%"
      );
      setNewCurrTarget(
        (prevTarget) => addCommas(Math.floor(prevTarget * 100)) + "%"
      );
    } else {
      setCurrTargetText("Current Target CPA");
      setRecommendedTargetText("Recommended Target CPA");
      setNewRecommendedTarget((prevTarget) => "$" + addCommas(prevTarget));
      setNewCurrTarget((prevTarget) => "$" + addCommas(prevTarget) );
    }

    if (
      taskTypeId === TaskTypeEnum.INCREASE_TARGET_ROAS.taskTypeId ||
      taskTypeId === TaskTypeEnum.INCREASE_TARGET_CPA.taskTypeId
    ) {
      setTargetChange("increases");
    } else {
      setTargetChange("decreases");
    }
  }, [taskTypeId]);

  return (
    <Fragment>
      <Grid container style={{ padding: 5 }} direction="column" rowSpacing={2}>
        <Grid
          item
          container
          xs={12}
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Grid item className="campaignNameAndType">
            <FormGroup row>
              <CustomFormControlLabelHead
                control={
                  taskDataById[id].status == 1 ? (
                    <Checkbox
                      color="secondary"
                      checked={true}
                      icon={<icons.CheckCircle className={classes.success} />}
                      checkedIcon={
                        <icons.CheckCircle className={classes.success} />
                      }
                      disabled={true}
                    />
                  ) : (
                    <Checkbox
                      color="secondary"
                      checked={taskDataById[id].selected}
                      onChange={(_, selected) =>
                        toggleSelection([taskDataById[id].taskDataId], selected)
                      }
                      disabled={isActivityReport ? true : false}
                    />
                  )
                }
              />
            </FormGroup>
            <Typography variant={"h4"} color="textPrimary" lineHeight={"2rem"}>
              <b>{name}</b>
            </Typography>
          </Grid>
          <Grid item>{caption}</Grid>
        </Grid>
        <Grid item>
          <TableContainer>
            <Table aria-label="simple table">
              <TableBody>
                <TableRow>
                  <TableCell className="greyBorder greyBackground">
                    <Typography
                      color="#1E344E"
                      fontSize={"0.875rem"}
                      fontWeight={500}
                    >
                      {currTargetText}
                    </Typography>
                  </TableCell>
                  <TableCell align="right" className="greyBorder">
                    <Typography color="#1E344E" fontWeight={"bold"}>
                      {newCurrTarget}
                    </Typography>
                  </TableCell>
                  <TableCell align="center" className="noBorder">
                    <EastIcon
                      className={
                        taskDataById[id].selected ? "orangeColor" : "greyColor"
                      }
                    />
                  </TableCell>
                  <TableCell
                    align="left"
                    className="greenBorder greenBackground"
                  >
                    <Typography
                      color="#00A850"
                      fontSize={"0.875rem"}
                      fontWeight={500}
                    >
                      {recommendedTargetText}
                    </Typography>
                  </TableCell>
                  <TableCell
                    align="left"
                    className="greenBorder whiteRightBorder"
                  >
                    {targetChange === "increases" ? (
                      <ArrowUpwardIcon
                        fontSize="small"
                        sx={{ color: "#00A850" }}
                      />
                    ) : targetChange === "decreases" ? (
                      <ArrowDownwardIcon
                        fontSize="small"
                        sx={{ color: "#CF0505" }}
                      />
                    ) : (
                      ""
                    )}
                  </TableCell>
                  <TableCell align="right" className="greenBorder">
                    <Typography
                      color="#F58120"
                      fontWeight={"bold"}
                      align="right"
                    >
                      {newRecommendedTarget}
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </Fragment>
  );
}

function UpgradeBiddingStrategyBox({
  taskDataById,
  name,
  caption,
  icon,
  children,
  isActivityReport,
  id,
  toggleSelection,
  currStrategy,
  recommendedStrategy,
  targetValue,
  targetType,
  classes,
}) {
  const [newRecommendedTarget, setNewRecommendedTarget] = useState(targetValue);

  useEffect(() => {
    if (targetType === "TROAS") {
      setNewRecommendedTarget((prevTarget) => Math.floor(prevTarget * 100));
    }
  }, [targetValue, targetType]);

  return (
    <Fragment>
      <Grid container style={{ padding: 5 }} direction="column" rowSpacing={2}>
        <Grid
          item
          container
          xs={12}
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          sx={{ paddingBottom: "2%" }}
        >
          <Grid item className="campaignNameAndType">
            <FormGroup row>
              <CustomFormControlLabelHead
                control={
                  taskDataById[id].status == 1 ? (
                    <Checkbox
                      color="secondary"
                      checked={true}
                      icon={<icons.CheckCircle className={classes.success} />}
                      checkedIcon={
                        <icons.CheckCircle className={classes.success} />
                      }
                      disabled={true}
                    />
                  ) : (
                    <Checkbox
                      color="secondary"
                      checked={taskDataById[id].selected}
                      onChange={(_, selected) =>
                        toggleSelection([taskDataById[id].taskDataId], selected)
                      }
                      disabled={isActivityReport ? true : false}
                    />
                  )
                }
              />
            </FormGroup>
            <Typography
              variant={"h4"}
              color="textPrimary"
              style={{ maxWidth: "auto", lineHeight: "2rem" }}
            >
              <b>{name}</b>
            </Typography>
          </Grid>
          <Grid item>{caption}</Grid>
        </Grid>

        <TableContainer>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell className="greyBorder greyBackground">
                  <Typography
                    color="#1E344E"
                    fontSize={"0.875rem"}
                    fontWeight={500}
                  >
                    Current Bid Strategy Type
                  </Typography>
                </TableCell>
                <TableCell className="greyBorder">
                  <Typography color="#1E344E" fontWeight={"bold"}>
                    {currStrategy}
                  </Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell
                  size="small"
                  sx={{ borderBottom: "none" }}
                ></TableCell>
              </TableRow>
            </TableBody>
            <TableBody>
              <TableRow>
                <TableCell className="greenBorder greenBackground">
                  <Typography
                    color="#00A850"
                    fontSize={"0.875rem"}
                    fontWeight={500}
                  >
                    Recommended Bid Strategy Type
                  </Typography>
                </TableCell>
                <TableCell align="left" className="greenBorder">
                  <Typography color="#1E344E" fontWeight={"bold"}>
                    {recommendedStrategy}
                  </Typography>
                </TableCell>
                <TableCell
                  align="right"
                  sx={{ borderBottom: "none" }}
                ></TableCell>
                <TableCell align="left" className="greenBorder greenBackground">
                  <Typography
                    color="#00A850"
                    fontSize={"0.875rem"}
                    fontWeight={500}
                  >
                    {targetType}
                  </Typography>
                </TableCell>
                <TableCell align="right" className="greenBorder">
                  <Typography color="#1E344E" fontWeight={"bold"} align="right">
                    {targetType === "TCPA" ? "$" : ""}
                    {addCommas(newRecommendedTarget)}
                    {targetType === "TROAS" ? "%" : ""}
                  </Typography>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Fragment>
  );
}

function getProgressWidth(dataIdList, taskDataById) {
  let progressWidth = 0;
  if (dataIdList != null && dataIdList.length > 0) {
    const totalTaskDataCount = dataIdList.length;
    const completedTDataCount = dataIdList.filter(
      (id) => taskDataById[id].status === 1
    ).length;
    progressWidth = (completedTDataCount / totalTaskDataCount) * 100;
  }
  return progressWidth;
}

const TypeTypography = withStyles((theme) => ({
  root: {
    fontSize: 10,
    color: theme.palette.text.primary,
  },
}))(Typography);

export function TypeTitle({ type, value }) {
  return (
    <TypeTypography variant={"caption"} color={"inherit"}>
      {type} type:{" "}
      <span
        style={{
          fontSize: 12,
          textTransform: "capitalize",
          fontWeight: "normal",
        }}
      >
        {value}
      </span>
    </TypeTypography>
  );
}

export function NewTaskTypeTitle({ type, value }) {
  return (
    <Typography fontSize="0.875rem" color={"inherit"}>
      {type} type:{" "}
      <span
        className={
          value === "Search"
            ? "searchCampaign"
            : value === "Shopping"
            ? "shoppingCampaign"
            : ""
        }
      >
        {value}
      </span>
    </Typography>
  );
}

function conciseURL(urlString) {
  try {
    const url = new URL(urlString);
    return url.pathname;
  } catch (e) {
    console.log("count not parse urlString = ", urlString, e);
    return urlString;
  }
}

function newConciseURL(urlString) {
  try {
    const url = new URL(urlString);
    return url.hostname + "/..." + url.pathname;
  } catch (e) {
    console.log("count not parse urlString = ", urlString, e);
    return urlString;
  }
}

const handleCampaignLevelSelection = (
  toggleSelection,
  list,
  selected,
  taskDataById
) => {
  const Ids = _.flatten(_.values(list));
  const campaignLevelTdIds = Ids.filter(
    (id) => taskDataById[id].status != 1 && taskDataById[id].status != -1
  );
  return toggleSelection(_.flatten(_.values(campaignLevelTdIds)), selected);
};
const handleCampaignLevelSelected = (list, taskDataById) => {
  const Ids = _.flatten(_.values(list));
  console.log("Ids=", Ids);
  console.log("Ids=", Ids.length);
  const campaignLevelTdIds = Ids.filter(
    (id) => taskDataById[id].status != 1 && taskDataById[id].status != -1
  );
  const selectedLength = campaignLevelTdIds.filter(
    (id) => taskDataById[id].selected == true
  );
  console.log("campaignLevelTdIdslength=", campaignLevelTdIds.length);
  console.log("selectedLength=", selectedLength.length);
  console.log(
    "selectedLength.length == campaignLevelTdIds.length=",
    selectedLength.length == campaignLevelTdIds.length
  );
  if (campaignLevelTdIds.length == 0 || selectedLength.length == 0)
    return false;
  return selectedLength.length == campaignLevelTdIds.length;
};

const isCampaignLevelTaskDataCompleted = (list, taskDataById) => {
  const Ids = _.flatten(_.values(list));
  const campaignLevelTdIds = Ids.filter(
    (id) => taskDataById[id].status == 1 || taskDataById[id].status == -1
  );
  if (campaignLevelTdIds.length == 0 || Ids.length == 0) return false;
  return Ids.length == campaignLevelTdIds.length;
};

export function withAdgroupsAndKeywords(
  classes,
  description,
  items,
  headerRenderer,
  selectionAlFlag,
  toggleSelection,
  taskDataById,
  taskTypeId,
  onSelectClearAll,
  dataIdList,
  isActivityReport
) {
  // const progressWidth = getProgressWidth(dataIdList, taskDataById);
  // console.log("items=", items);
  // console.log("taskTypeId=", taskTypeId);
  const handleData = (list) => {
    console.log("list=", list);
  };
  return (
    <div>
      <Card className={classes.card}>
        <CardContent>
          <Grid container>
            <Grid item md={12}>
              <Typography
                color="textPrimary"
                variant={"h3"}
                className={classes.campaignTitle}
                style={{ maxWidth: "auto" }}
              >
                <b>({_.keys(items).length}) Campaign(s)</b>
              </Typography>
              {_.map(items, (list, caption) => (
                <div>
                  {/*{handleCampaignLevelSelection(list)}*/}
                  <Paper className={classes.tdPaper}>
                    {/*<pre>{caption}</pre>*/}
                    {headerRenderer(caption)}
                    <br />
                    <Grid container spacing={2}>
                      <Grid item md={12}>
                        <Table className={classes.table}>
                          <TableHead>
                            <TableRow>
                              {taskTypeId != 42 ? (
                                <CustomTableCellHead>
                                  Ad Group
                                </CustomTableCellHead>
                              ) : null}
                              {taskTypeId == 13 ? (
                                <CustomTableCellHead>
                                  Headline
                                </CustomTableCellHead>
                              ) : null}
                              {/*<CustomTableCell>Keywords</CustomTableCell>*/}
                              <CustomKeywordsTableCellHead>
                                <FormGroup row>
                                  <CustomFormControlLabelHead
                                    control={
                                      isCampaignLevelTaskDataCompleted(
                                        list,
                                        taskDataById
                                      ) ? (
                                        <Checkbox
                                          color="secondary"
                                          checked={true}
                                          icon={
                                            <icons.CheckCircle
                                              className={classes.success}
                                            />
                                          }
                                          checkedIcon={
                                            <icons.CheckCircle
                                              className={classes.success}
                                            />
                                          }
                                          // onChange={() => onSelectClearAll()}
                                          // onChange={(_, selected) => toggleSelection(_.map(_.values(list), (array) => array), selected)}
                                          disabled={true}
                                        />
                                      ) : (
                                        <Checkbox
                                          color="secondary"
                                          checked={handleCampaignLevelSelected(
                                            list,
                                            taskDataById
                                          )}
                                          onChange={(_, selected) =>
                                            handleCampaignLevelSelection(
                                              toggleSelection,
                                              list,
                                              selected,
                                              taskDataById
                                            )
                                          }
                                          disabled={
                                            isActivityReport ? true : false
                                          }
                                        />
                                      )
                                    }
                                    label={taskTypeId == 13 ? "Url" : "Keyword"}
                                  />
                                </FormGroup>
                              </CustomKeywordsTableCellHead>
                              {/*<CustomTableCell align="right">Bids</CustomTableCell>*/}
                              {taskTypeId != 47 &&
                              taskTypeId != 13 &&
                              taskTypeId != 2 &&
                              taskTypeId != 38 &&
                              taskTypeId != 5 &&
                              taskTypeId != 7 &&
                              taskTypeId != 41 &&
                              taskTypeId != 42 &&
                              taskTypeId != 14 &&
                              taskTypeId != 48 ? (
                                <CustomTableCellHead>Bid</CustomTableCellHead>
                              ) : null}
                              {/*{taskTypeId == 13 ? "Keyword Text" : "Bids"}</CustomTableCellHead>*/}
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {taskTypeId == 42 || taskTypeId == 22 ? (
                              <Fragment>
                                {_.isArray(_.values(list)) ? (
                                  <Fragment>
                                    {_.map(_.values(list), (id) => (
                                      <GetTableRow
                                        key={id}
                                        toggleSelection={toggleSelection}
                                        classes={classes}
                                        taskDataId={id}
                                        taskDataById={taskDataById}
                                        isActivityReport={isActivityReport}
                                      />
                                    ))}
                                  </Fragment>
                                ) : null}
                              </Fragment>
                            ) : (
                              <Fragment>
                                {_.isArray(_.values(list)) ? (
                                  <Fragment>
                                    {handleData(list)}
                                    {_.map(_.values(list), (array) =>
                                      _.map(array, (id) => (
                                        <GetTableRow
                                          key={id}
                                          toggleSelection={toggleSelection}
                                          classes={classes}
                                          taskDataId={id}
                                          taskDataById={taskDataById}
                                          isActivityReport={isActivityReport}
                                        />
                                      ))
                                    )}
                                  </Fragment>
                                ) : null}
                              </Fragment>
                            )}
                          </TableBody>
                        </Table>
                      </Grid>
                    </Grid>
                  </Paper>
                </div>
              ))}
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </div>
  );
}

export function campaign(
  classes,
  description,
  items,
  headerRenderer,
  selectionAlFlag,
  toggleSelection,
  taskDataById,
  taskTypeId,
  onSelectClearAll,
  dataIdList,
  isActivityReport
) {
  console.log("items=", items);
  return (
    <div>
      <Card className={classes.card}>
        <CardContent>
          <Grid container spacing={2}>
            {_.map(items, (id) => (
              <Grid item sm={12} xs={12} md={6}>
                <Grid container spacing={2}>
                  <Grid item>
                    <FormGroup row>
                      <CustomFormControlLabelHead
                        control={
                          taskDataById[id].status == 1 ? (
                            <Checkbox
                              color="secondary"
                              checked={true}
                              icon={
                                <icons.CheckCircle
                                  className={classes.success}
                                />
                              }
                              checkedIcon={
                                <icons.CheckCircle
                                  className={classes.success}
                                />
                              }
                              disabled={true}
                            />
                          ) : (
                            <Checkbox
                              color="secondary"
                              checked={taskDataById[id].selected}
                              onChange={(_, selected) =>
                                toggleSelection(
                                  [taskDataById[id].taskDataId],
                                  selected
                                )
                              }
                              disabled={isActivityReport ? true : false}
                            />
                          )
                        }
                      />
                    </FormGroup>
                  </Grid>
                  <Grid item sm={10} xs={10} md={10}>
                    <GenericBox
                      icon={icons.Campaign}
                      name={taskDataById[id].campaignName}
                      caption={
                        <TypeTitle
                          type="Campaign"
                          value={taskDataById[id].campaignType}
                        />
                      }
                    />
                  </Grid>
                </Grid>
              </Grid>
            ))}
          </Grid>
        </CardContent>
      </Card>
    </div>
  );
}

function GetCheckBox({
  toggleSelection,
  taskDataId,
  taskDataById,
  isActivityReport,
  classes,
}) {
  const taskData = taskDataById[taskDataId];
  console.log("the hourly task status = ", taskData);
  return (
    <FormGroup row>
      <HourlyFormControlLabel
        control={
          taskData.status == 1 || taskData.status == -1 ? (
            <Checkbox
              color="secondary"
              checked={true}
              icon={<icons.CheckCircle className={classes.success} />}
              checkedIcon={
                taskData.status == 1 ? (
                  <icons.CheckCircle className={classes.success} />
                ) : (
                  <Warning className={classes.warningIconStyle} />
                )
              }
              disabled={true}
            />
          ) : (
            <Checkbox
              color="secondary"
              checked={taskData.selected}
              onChange={(_, selected) =>
                toggleSelection([taskDataId], selected)
              }
              disabled={isActivityReport ? true : false}
            />
          )
        }
        label={taskData.keywordText}
      />
    </FormGroup>
  );
}

export function withSchedule2(
  classes,
  description,
  items,
  headerRenderer,
  selectionAlFlag,
  toggleSelection,
  taskDataById,
  taskTypeId,
  onSelectClearAll,
  dataIdList,
  isActivityReport
) {
  console.log("logged=", items);
  console.log("taskDataById=", taskDataById);
  return (
    <div>
      <Card className={classes.card}>
        <CardContent>
          <Grid container>
            <Grid item md={12}>
              <Typography
                color="textPrimary"
                variant={"h3"}
                className={classes.campaignTitle}
                style={{ maxWidth: "auto" }}
              >
                <b>({_.keys(items).length}) Campaign(s)</b>
              </Typography>
              <br />
              {_.map(items, (list, caption) => (
                <div>
                  <Paper className={classes.houlryPaper}>
                    <Grid container spacing={2}>
                      <Grid
                        item
                        className={classNames(
                          classes.taskView,
                          "flex alignItemsflexStart"
                        )}
                      >
                        <GetCheckBox
                          toggleSelection={toggleSelection}
                          taskDataId={list[0]}
                          taskDataById={taskDataById}
                          isActivityReport={isActivityReport}
                          classes={classes}
                        />
                        {headerRenderer(caption)}
                        <br />
                        <br />
                        <br />
                      </Grid>
                      <Grid item sm={12} xs={12} md={12}>
                        <Schedule2
                          taskDataList={
                            isActivityReport
                              ? list.map((id) => taskDataById[id])
                              : taskDataById[list[0]].taskDataList
                          }
                          toggleSelection={toggleSelection}
                          classes={classes}
                          taskDataId={list[0]}
                          taskDataById={taskDataById}
                        />
                      </Grid>
                    </Grid>
                  </Paper>
                </div>
              ))}
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </div>
  );
}

export function withAdgroupsAndKeywordsOldNewBid(
  classes,
  description,
  items,
  headerRenderer,
  selectionAlFlag,
  toggleSelection,
  taskDataById,
  taskTypeId,
  onSelectClearAll,
  dataIdList,
  isActivityReport
) {
  // const progressWidth = getProgressWidth(dataIdList, taskDataById);
  console.log("items keywords impr=", items);
  // console.log("items123=",items);
  return (
    <div>
      <Card className={classes.card}>
        <CardContent>
          <Grid container>
            <Grid item md={12}>
              {/* todo need to calculate the Campaigns count*/}
              <Typography
                color="textPrimary"
                variant={"h3"}
                className={classes.campaignTitle}
                style={{ maxWidth: "auto" }}
              >
                <b>({_.keys(items).length}) Campaign(s)</b>
              </Typography>
              <br />
              {_.map(items, (list, caption) => (
                <div>
                  <Paper className={classes.tdPaper}>
                    {/*<pre>{caption}</pre>*/}
                    {headerRenderer(caption)}
                    <br />
                    <Grid container spacing={2}>
                      <Grid item md={12}>
                        <Table className={classes.table}>
                          <TableHead>
                            <TableRow>
                              <CustomTableCellHead>
                                Ad Group
                              </CustomTableCellHead>
                              {/*<CustomTableCell>Keywords</CustomTableCell>*/}
                              <CustomKeywordsTableCellHead>
                                <FormGroup row>
                                  <CustomFormControlLabelHead
                                    control={
                                      isCampaignLevelTaskDataCompleted(
                                        list,
                                        taskDataById
                                      ) ? (
                                        <Checkbox
                                          color="secondary"
                                          checked={true}
                                          icon={
                                            <icons.CheckCircle
                                              className={classes.success}
                                            />
                                          }
                                          checkedIcon={
                                            <icons.CheckCircle
                                              className={classes.success}
                                            />
                                          }
                                          // onChange={() => onSelectClearAll()}
                                          disabled={true}
                                        />
                                      ) : (
                                        <Checkbox
                                          color="secondary"
                                          checked={handleCampaignLevelSelected(
                                            list,
                                            taskDataById
                                          )}
                                          onChange={(_, selected) =>
                                            handleCampaignLevelSelection(
                                              toggleSelection,
                                              list,
                                              selected,
                                              taskDataById
                                            )
                                          }
                                          disabled={
                                            isActivityReport ? true : false
                                          }
                                        />
                                      )
                                    }
                                    label={"Keyword"}
                                  />
                                </FormGroup>
                              </CustomKeywordsTableCellHead>
                              {/*<CustomTableCell align="right">Bids</CustomTableCell>*/}
                              {/*<CustomTableCellHead>{"Match Type"}</CustomTableCellHead>*/}

                              <CustomTableCellHead>
                                {"Bids"}&nbsp;&nbsp;
                                {taskTypeId == 8 || taskTypeId == 16
                                  ? icons.upBigArrow
                                  : taskTypeId == 9 || taskTypeId == 19
                                  ? icons.downBigArrow
                                  : null}
                              </CustomTableCellHead>
                              {/*<CustomTableCellHead>{"Bid"}&nbsp;&nbsp;</CustomTableCellHead>*/}
                              {/*<CustomTableCellHead>{"New Bid"}</CustomTableCellHead>*/}
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {_.isArray(_.values(list)) ? (
                              <Fragment>
                                {_.map(list, (tdlist, grpName) => (
                                  <Fragment>
                                    {/*<pre>{grpName}</pre>*/}
                                    {_.map(tdlist, (id) => (
                                      <GetTableRowWithAdgroupsAndKeywordsOldNewBid
                                        toggleSelection={toggleSelection}
                                        classes={classes}
                                        taskDataId={id}
                                        taskDataById={taskDataById}
                                        isActivityReport={isActivityReport}
                                      />
                                    ))}
                                  </Fragment>
                                ))}
                              </Fragment>
                            ) : null}
                          </TableBody>
                        </Table>
                      </Grid>
                    </Grid>
                  </Paper>
                </div>
              ))}
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </div>
  );
}

export function productGroupDimCPC(
  classes,
  description,
  items,
  headerRenderer,
  selectionAlFlag,
  toggleSelection,
  taskDataById,
  taskTypeId,
  onSelectClearAll,
  dataIdList,
  isActivityReport
) {
  // const progressWidth = getProgressWidth(dataIdList, taskDataById);
  return (
    <div>
      <Card className={classes.card}>
        <CardContent>
          <Grid container>
            <Grid item md={12}>
              <Typography
                color="textPrimary"
                variant={"h3"}
                className={classes.campaignTitle}
                style={{ maxWidth: "auto" }}
              >
                <b>({_.keys(items).length}) Campaign(s)</b>
              </Typography>
              <br />
              {_.map(items, (list, caption) => (
                <div>
                  <Paper className={classes.tdPaper}>
                    {/*<pre>{caption}</pre>*/}
                    {headerRenderer(caption)}
                    <br />
                    <Grid container spacing={2}>
                      <Grid item md={12}>
                        <Table className={classes.table}>
                          <TableHead>
                            <TableRow>
                              <CustomTableCellHead>
                                Ad Group
                              </CustomTableCellHead>
                              {/*<CustomTableCell>Keywords</CustomTableCell>*/}
                              <CustomTableCellHead>
                                <FormGroup row>
                                  <CustomFormControlLabelHead
                                    control={
                                      isCampaignLevelTaskDataCompleted(
                                        list,
                                        taskDataById
                                      ) ? (
                                        <Checkbox
                                          color="secondary"
                                          checked={true}
                                          icon={
                                            <icons.CheckCircle
                                              className={classes.success}
                                            />
                                          }
                                          checkedIcon={
                                            <icons.CheckCircle
                                              className={classes.success}
                                            />
                                          }
                                          // onChange={() => onSelectClearAll()}
                                          disabled={true}
                                        />
                                      ) : (
                                        <Checkbox
                                          color="secondary"
                                          checked={
                                            taskTypeId == 28 ||
                                            handleCampaignLevelSelected(
                                              list,
                                              taskDataById
                                            )
                                          }
                                          onChange={(_, selected) => {
                                            if(taskTypeId != 28){
                                              handleCampaignLevelSelection(
                                                toggleSelection,
                                                list,
                                                selected,
                                                taskDataById
                                              );
                                            }                                               
                                          }}
                                          disabled={
                                            isActivityReport ? true : false
                                          }
                                        />
                                      )
                                    }
                                    label={"Product group"}
                                  />
                                </FormGroup>
                              </CustomTableCellHead>
                              {/*<CustomTableCell align="right">Bids</CustomTableCell>*/}
                              {/* {(taskTypeId == 29 || taskTypeId == 30) ?
                                                            null :
                                                            <CustomTableCellHead>{"Product Dimension"}</CustomTableCellHead>
                                                        }*/}
                              <CustomTableCellHead>
                                {"Bid"}&nbsp;&nbsp;
                                {taskTypeId == 29
                                  ? icons.upBigArrow
                                  : taskTypeId == 30
                                  ? icons.downBigArrow
                                  : null}
                              </CustomTableCellHead>
                              {/*<CustomTableCellHead>{"New Bid"}</CustomTableCellHead>*/}
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {_.isArray(_.values(list)) ? (
                              <Fragment>
                                {_.map(list, (tdlist, grpName) => (
                                  <Fragment>
                                    {/*<pre>{grpName}</pre>*/}
                                    {_.map(tdlist, (id) => (
                                      <GetTableRowProductGroupDimCPC
                                        toggleSelection={toggleSelection}
                                        classes={classes}
                                        taskDataId={id}
                                        taskDataById={taskDataById}
                                        isActivityReport={isActivityReport}
                                      />
                                    ))}
                                  </Fragment>
                                ))}
                              </Fragment>
                            ) : null}
                          </TableBody>
                        </Table>
                      </Grid>
                    </Grid>
                  </Paper>
                </div>
              ))}
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </div>
  );
}

export function addRemoveProductGroups(
  classes,
  description,
  items,
  headerRenderer,
  selectionAlFlag,
  toggleSelection,
  taskDataById,
  taskTypeId,
  onSelectClearAll,
  dataIdList,
  isActivityReport
) {
  // const progressWidth = getProgressWidth(dataIdList, taskDataById);
  return (
    <div>
      <Card className={classes.card}>
        <CardContent>
          <Grid container>
            <Grid item>
              <Typography
                color="textPrimary"
                variant={"h3"}
                className={classes.campaignTitle}
                style={{ maxWidth: "auto" }}
              >
                <b>({_.keys(items).length}) Campaign(s)</b>
              </Typography>
              <br />
              {_.map(items, (list, caption) => (
                <div>
                  <Paper className={classes.tdPaper}>
                    {/*<pre>{caption}</pre>*/}
                    {headerRenderer(caption)}
                    <Grid container spacing={2}>
                      <Grid item md={12}>
                        <Table className={classes.table}>
                          <TableHead>
                            <TableRow>
                              <CustomTableCellHead>
                                Ad Group
                              </CustomTableCellHead>
                              {/*<CustomTableCell>Keywords</CustomTableCell>*/}
                              <CustomTableCellHead>
                                <FormGroup row>
                                  <CustomFormControlLabelHead
                                    control={
                                      isCampaignLevelTaskDataCompleted(
                                        list,
                                        taskDataById
                                      ) ? (
                                        <Checkbox
                                          color="secondary"
                                          checked={true}
                                          icon={
                                            <icons.CheckCircle
                                              className={classes.success}
                                            />
                                          }
                                          checkedIcon={
                                            <icons.CheckCircle
                                              className={classes.success}
                                            />
                                          }
                                          // onChange={() => onSelectClearAll()}
                                          disabled={true}
                                        />
                                      ) : (
                                        <Checkbox
                                          color="secondary"
                                          checked={handleCampaignLevelSelected(
                                            list,
                                            taskDataById
                                          )}
                                          onChange={(_, selected) =>
                                            handleCampaignLevelSelection(
                                              toggleSelection,
                                              list,
                                              selected,
                                              taskDataById
                                            )
                                          }
                                          disabled={
                                            isActivityReport ? true : false
                                          }
                                        />
                                      )
                                    }
                                    label={"Product group"}
                                  />
                                </FormGroup>
                              </CustomTableCellHead>
                              {/*<CustomTableCell align="right">Bids</CustomTableCell>*/}
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {_.isArray(_.values(list)) ? (
                              <Fragment>
                                {_.map(list, (tdlist, grpName) => (
                                  <Fragment>
                                    {/*<pre>{grpName}</pre>*/}
                                    {_.map(tdlist, (id) => (
                                      <GetTableRowAddRemoveProductGroups
                                        toggleSelection={toggleSelection}
                                        classes={classes}
                                        taskDataId={id}
                                        taskDataById={taskDataById}
                                        isActivityReport={isActivityReport}
                                      />
                                    ))}
                                  </Fragment>
                                ))}
                                {/* {_.map(_.values(list)[0], Id => (
                                                                <GetTableRowAddRemoveProductGroups
                                                                    toggleSelection={toggleSelection}
                                                                    classes={classes}
                                                                    taskDataId={Id}
                                                                    taskDataById={taskDataById}
                                                                    isActivityReport={isActivityReport}
                                                                />
                                                            ))}*/}
                              </Fragment>
                            ) : null}
                          </TableBody>
                        </Table>
                      </Grid>
                    </Grid>
                  </Paper>
                </div>
              ))}
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </div>
  );
}

export function campaignNameTypeBudget(
  classes,
  description,
  items,
  headerRenderer,
  selectionAlFlag,
  toggleSelection,
  taskDataById,
  taskTypeId,
  onSelectClearAll,
  dataIdList,
  isActivityReport
) {
  // const progressWidth = getProgressWidth(dataIdList, taskDataById);
  console.log("campaignNameTypeBudget", items);
  return (
    <div>
      <Card className={classes.card}>
        <CardContent>
          <Grid container>
            <Grid item md={12}>
              <Typography
                color="textPrimary"
                variant={"h3"}
                className={classes.campaignTitle}
                style={{ maxWidth: "auto" }}
              >
                <b>({_.keys(items).length}) Campaign(s)</b>
              </Typography>
              <br />
              <Grid container>
                {_.map(items, (list, caption) => (
                  <Grid item md={6}>
                    <Paper className={classes.tdPaper}>
                      {/*<pre>{caption}</pre>*/}
                      {headerRenderer(caption)}
                      <br />
                      <Grid container spacing={2}>
                        <Grid item md={6}>
                          <Table className={classes.table}>
                            <TableHead>
                              <TableRow>
                                {/*<CustomTableCellHead>Old Budget</CustomTableCellHead>*/}
                                {/*<CustomTableCell>Keywords</CustomTableCell>*/}
                                <CustomTableCellHead>
                                  <FormGroup row>
                                    <CustomFormControlLabelHead
                                      control={
                                        isCampaignLevelTaskDataCompleted(
                                          list,
                                          taskDataById
                                        ) ? (
                                          <Checkbox
                                            color="secondary"
                                            checked={true}
                                            icon={
                                              <icons.CheckCircle
                                                className={classes.success}
                                              />
                                            }
                                            checkedIcon={
                                              <icons.CheckCircle
                                                className={classes.success}
                                              />
                                            }
                                            // onChange={() => onSelectClearAll()}
                                            disabled={true}
                                          />
                                        ) : (
                                          <Checkbox
                                            color="secondary"
                                            checked={handleCampaignLevelSelected(
                                              list,
                                              taskDataById
                                            )}
                                            onChange={(_, selected) =>
                                              handleCampaignLevelSelection(
                                                toggleSelection,
                                                list,
                                                selected,
                                                taskDataById
                                              )
                                            }
                                            disabled={
                                              isActivityReport ? true : false
                                            }
                                          />
                                        )
                                      }
                                      label={
                                        <span>
                                          {"Budget"}&nbsp;&nbsp;
                                          {taskTypeId == 33
                                            ? icons.upBigArrow
                                            : icons.downBigArrow}
                                        </span>
                                      }
                                    />
                                  </FormGroup>
                                </CustomTableCellHead>
                                {/*<CustomTableCell align="right">Bids</CustomTableCell>*/}
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {_.isArray(_.values(list)) ? (
                                <Fragment>
                                  {_.map(_.values(list), (Id) => (
                                    <GetTableRowCampaignNameTypeBudget
                                      toggleSelection={toggleSelection}
                                      classes={classes}
                                      taskDataId={Id}
                                      taskDataById={taskDataById}
                                      isActivityReport={isActivityReport}
                                    />
                                  ))}
                                </Fragment>
                              ) : null}
                            </TableBody>
                          </Table>
                        </Grid>
                      </Grid>
                    </Paper>
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </div>
  );
}

export function campaignNameTypeBidAdjustment(
  classes,
  description,
  items,
  headerRenderer,
  selectionAlFlag,
  toggleSelection,
  taskDataById,
  taskTypeId,
  onSelectClearAll,
  dataIdList,
  isActivityReport
) {
  console.log("campaignNameTypeBudget", items);
  return (
    <div>
      <Card className={classes.card}>
        <CardContent>
          <Grid container>
            <Grid item md={12}>
              <Typography
                color="textPrimary"
                variant={"h3"}
                className={classes.campaignTitle}
                style={{ maxWidth: "auto" }}
              >
                <b>({_.keys(items).length}) Campaign(s)</b>
              </Typography>
              <br />
              <Grid container>
                {_.map(items, (list, caption) => (
                  <Grid item md={6}>
                    <Paper className={classes.tdPaper}>
                      {/*<pre>{caption}</pre>*/}
                      {headerRenderer(caption)}
                      <br />
                      <Grid container spacing={2}>
                        <Grid item md={6}>
                          <Table className={classes.table}>
                            <TableHead>
                              <TableRow>
                                {/*<CustomTableCellHead>Old Budget</CustomTableCellHead>*/}
                                {/*<CustomTableCell>Keywords</CustomTableCell>*/}
                                <CustomTableCellHead>
                                  <FormGroup row>
                                    <CustomFormControlLabelHead
                                      control={
                                        isCampaignLevelTaskDataCompleted(
                                          list,
                                          taskDataById
                                        ) ? (
                                          <Checkbox
                                            color="secondary"
                                            checked={true}
                                            icon={
                                              <icons.CheckCircle
                                                className={classes.success}
                                              />
                                            }
                                            checkedIcon={
                                              <icons.CheckCircle
                                                className={classes.success}
                                              />
                                            }
                                            // onChange={() => onSelectClearAll()}
                                            disabled={true}
                                          />
                                        ) : (
                                          <Checkbox
                                            color="secondary"
                                            checked={handleCampaignLevelSelected(
                                              list,
                                              taskDataById
                                            )}
                                            onChange={(_, selected) =>
                                              handleCampaignLevelSelection(
                                                toggleSelection,
                                                list,
                                                selected,
                                                taskDataById
                                              )
                                            }
                                            disabled={
                                              isActivityReport ? true : false
                                            }
                                          />
                                        )
                                      }
                                      label={<span>{"Bid Limit"}</span>}
                                    />
                                  </FormGroup>
                                </CustomTableCellHead>
                                {/*<CustomTableCell align="right">Bids</CustomTableCell>*/}
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {_.isArray(_.values(list)) ? (
                                <Fragment>
                                  {_.map(_.values(list), (Id) => (
                                    <GetTableRowCampaignNameTypeBidLimit
                                      toggleSelection={toggleSelection}
                                      classes={classes}
                                      taskDataId={Id}
                                      taskDataById={taskDataById}
                                      isActivityReport={isActivityReport}
                                    />
                                  ))}
                                </Fragment>
                              ) : null}
                            </TableBody>
                          </Table>
                        </Grid>
                      </Grid>
                    </Paper>
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </div>
  );
}

export function campaignNameBudget(
  classes,
  description,
  items,
  headerRenderer,
  selectionAlFlag,
  toggleSelection,
  taskDataById,
  taskTypeId,
  onSelectClearAll,
  dataIdList,
  isActivityReport
) {
  console.log("campaignNameTypeBudget", items);
  return (
    <div>
      <Card className={classes.card}>
        <CardContent>
          <Grid container>
            <Grid item>
              <Typography
                color="textPrimary"
                variant={"h3"}
                className={classes.campaignTitle}
                style={{ maxWidth: "auto" }}
              >
                <b>({_.keys(items).length}) Campaign(s)</b>
              </Typography>
              <br />
              <Grid container>
                <Grid item md={12}>
                  <Paper className={classes.tdPaper}>
                    <Grid container spacing={2}>
                      <Grid item md={6}>
                        <Table className={classes.table}>
                          <TableHead>
                            <TableRow>
                              <CustomTableCellHead>
                                Campaign
                              </CustomTableCellHead>
                              {/*<CustomTableCell>Keywords</CustomTableCell>*/}
                              <CustomTableCellHead>
                                <FormGroup row>
                                  <CustomFormControlLabelHead
                                    // control={
                                    //     <Checkbox color="secondary"
                                    //         checked={handleCampaignLevelSelected(items, taskDataById)}
                                    //         onChange={(_, selected) => handleCampaignLevelSelection(toggleSelection, items, selected, taskDataById)}
                                    //         disabled={isActivityReport ? true : false}
                                    //     />
                                    // }
                                    control={
                                      isCampaignLevelTaskDataCompleted(
                                        items,
                                        taskDataById
                                      ) ? (
                                        <Checkbox
                                          color="secondary"
                                          checked={true}
                                          icon={
                                            <icons.CheckCircle
                                              className={classes.success}
                                            />
                                          }
                                          checkedIcon={
                                            <icons.CheckCircle
                                              className={classes.success}
                                            />
                                          }
                                          // onChange={() => onSelectClearAll()}
                                          // onChange={(_, selected) => toggleSelection(_.map(_.values(list), (array) => array), selected)}
                                          disabled={true}
                                        />
                                      ) : (
                                        <Checkbox
                                          color="secondary"
                                          checked={handleCampaignLevelSelected(
                                            items,
                                            taskDataById
                                          )}
                                          onChange={(_, selected) =>
                                            handleCampaignLevelSelection(
                                              toggleSelection,
                                              items,
                                              selected,
                                              taskDataById
                                            )
                                          }
                                          disabled={
                                            isActivityReport ? true : false
                                          }
                                        />
                                      )
                                    }
                                    label={"Budget"}
                                  />
                                </FormGroup>
                              </CustomTableCellHead>
                              {/*<CustomTableCell align="right">Bids</CustomTableCell>*/}
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {_.isArray(items) ? (
                              <Fragment>
                                {_.map(items, (Id) => (
                                  <GetTableRowCampaignNameBudget
                                    toggleSelection={toggleSelection}
                                    classes={classes}
                                    taskDataId={Id}
                                    taskDataById={taskDataById}
                                    isActivityReport={isActivityReport}
                                  />
                                ))}
                              </Fragment>
                            ) : null}
                          </TableBody>
                        </Table>
                      </Grid>
                    </Grid>
                  </Paper>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </div>
  );
}

export function withKeywordsAndMatchType(
  classes,
  description,
  items,
  headerRenderer,
  selectionAlFlag,
  toggleSelection,
  taskDataById,
  taskTypeId,
  onSelectClearAll,
  dataIdList,
  isActivityReport
) {
  // const progressWidth = getProgressWidth(dataIdList, taskDataById);
  console.log("items 12=", items);
  return (
    <div>
      <Card className={classes.card}>
        <CardContent>
          <Grid container>
            <Grid item md={12}>
              <Typography
                color="textPrimary"
                variant={"h3"}
                className={classes.campaignTitle}
                style={{ maxWidth: "auto" }}
              >
                <b>({_.keys(items).length}) Campaign(s)</b>
              </Typography>
              <br />
              {_.map(items, (list, caption) => (
                <div>
                  <Paper className={classes.tdPaper}>
                    {/*<pre>{caption}</pre>*/}
                    {headerRenderer(caption)}
                    <br />
                    <Grid container spacing={2}>
                      <Grid item md={12}>
                        <Table className={classes.table}>
                          <TableHead>
                            <TableRow>
                              <CustomTableCellHead>
                                Ad Group
                              </CustomTableCellHead>
                              {/*<CustomTableCell>Keywords</CustomTableCell>*/}
                              <CustomTableCellHead>
                                <FormGroup row>
                                  <CustomFormControlLabelHead
                                    control={
                                      isCampaignLevelTaskDataCompleted(
                                        list,
                                        taskDataById
                                      ) ? (
                                        <Checkbox
                                          color="secondary"
                                          checked={true}
                                          icon={
                                            <icons.CheckCircle
                                              className={classes.success}
                                            />
                                          }
                                          checkedIcon={
                                            <icons.CheckCircle
                                              className={classes.success}
                                            />
                                          }
                                          onChange={() => onSelectClearAll()}
                                          disabled={true}
                                        />
                                      ) : (
                                        <Checkbox
                                          color="secondary"
                                          checked={handleCampaignLevelSelected(
                                            list,
                                            taskDataById
                                          )}
                                          onChange={(_, selected) =>
                                            handleCampaignLevelSelection(
                                              toggleSelection,
                                              list,
                                              selected,
                                              taskDataById
                                            )
                                          }
                                          disabled={
                                            isActivityReport ? true : false
                                          }
                                        />
                                      )
                                    }
                                    label={"Keyword"}
                                  />
                                </FormGroup>
                              </CustomTableCellHead>
                              {/*<CustomTableCell align="right">Bids</CustomTableCell>*/}
                              {/*<CustomTableCellHead>{"Urls"}</CustomTableCellHead>*/}
                              {/*<CustomTableCellHead>{"Match Type"}</CustomTableCellHead>*/}
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {_.isArray(_.values(list)) ? (
                              <Fragment>
                                {_.map(list, (tdlist, grpName) => (
                                  <Fragment>
                                    {/*<pre>{grpName}</pre>*/}
                                    {_.map(tdlist, (id) => (
                                      <GetTableRowKeywordUrls
                                        toggleSelection={toggleSelection}
                                        classes={classes}
                                        taskDataId={id}
                                        taskDataById={taskDataById}
                                        isActivityReport={isActivityReport}
                                      />
                                    ))}
                                  </Fragment>
                                ))}
                              </Fragment>
                            ) : /*<Fragment>
                                                            {_.map(_.values(list)[0], Id => (
                                                                <GetTableRowKeywordUrls
                                                                    toggleSelection={toggleSelection}
                                                                    classes={classes}
                                                                    taskDataId={Id}
                                                                    taskDataById={taskDataById}
                                                                    isActivityReport={isActivityReport}
                                                                />
                                                            ))}
                                                        </Fragment>*/
                            null}
                          </TableBody>
                        </Table>
                      </Grid>
                    </Grid>
                  </Paper>
                </div>
              ))}
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </div>
  );
}

export function adgroupBidModifier(
  classes,
  description,
  items,
  headerRenderer,
  selectionAlFlag,
  toggleSelection,
  taskDataById,
  taskTypeId,
  onSelectClearAll,
  dataIdList,
  isActivityReport
) {
  // const progressWidth = getProgressWidth(dataIdList, taskDataById);
  console.log("items adgroupBidModifier=", items);
  return (
    <div>
      <Card className={classes.card}>
        <CardContent>
          <Typography
            color="textPrimary"
            variant={"h3"}
            className={classes.campaignTitle}
            style={{ maxWidth: "auto" }}
          >
            <b>({_.keys(items).length}) Campaign(s)</b>
          </Typography>
          <Grid container>
            {_.map(items, (list, caption) => (
              <Grid item md={12}>
                {/* todo need to calculate the Campaigns count*/}
                <br />
                <div>
                  <Paper className={classes.tdPaper}>
                    {/*<pre>{caption}</pre>*/}
                    {/*{(taskTypeId == 23 || taskTypeId == 24 || taskTypeId == 21 || taskTypeId == 1) ?*/}
                    {headerRenderer(caption)}
                    <br />
                    <Grid container spacing={2}>
                      <Grid item md={12}>
                        <Table className={classes.table}>
                          <TableHead>
                            <TableRow>
                              <CustomTableCellHead>
                                {" "}
                                Ad Group
                              </CustomTableCellHead>
                              <CustomTableCellHead>
                                <FormGroup row>
                                  <CustomFormControlLabelHead
                                    control={
                                      isCampaignLevelTaskDataCompleted(
                                        list,
                                        taskDataById
                                      ) ? (
                                        <Checkbox
                                          color="secondary"
                                          checked={true}
                                          icon={
                                            <icons.CheckCircle
                                              className={classes.success}
                                            />
                                          }
                                          checkedIcon={
                                            <icons.CheckCircle
                                              className={classes.success}
                                            />
                                          }
                                          onChange={() => onSelectClearAll()}
                                          disabled={true}
                                        />
                                      ) : (
                                        <Checkbox
                                          color="secondary"
                                          checked={handleCampaignLevelSelected(
                                            list,
                                            taskDataById
                                          )}
                                          onChange={(_, selected) =>
                                            handleCampaignLevelSelection(
                                              toggleSelection,
                                              list,
                                              selected,
                                              taskDataById
                                            )
                                          }
                                          disabled={
                                            isActivityReport ? true : false
                                          }
                                        />
                                      )
                                    }
                                    label={
                                      <span>
                                        {"Bid Modifier"}&nbsp;&nbsp;
                                        {taskTypeId == 26 || taskTypeId == 15
                                          ? icons.downBigArrow
                                          : icons.upBigArrow}
                                      </span>
                                    }
                                  />
                                </FormGroup>
                              </CustomTableCellHead>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {_.isArray(_.map(_.values(list))) ? (
                              <Fragment>
                                {_.map(
                                  _.map(_.values(list), (id) => (
                                    <GetTableRowAdgroupBidModifiers
                                      toggleSelection={toggleSelection}
                                      classes={classes}
                                      taskDataId={id}
                                      taskDataById={taskDataById}
                                      isActivityReport={isActivityReport}
                                    />
                                  ))
                                )}
                              </Fragment>
                            ) : null}
                          </TableBody>
                        </Table>
                      </Grid>
                    </Grid>
                  </Paper>
                </div>
              </Grid>
            ))}
          </Grid>
        </CardContent>
      </Card>
    </div>
  );
}

export function regionAndBid(
  classes,
  description,
  items,
  headerRenderer,
  selectionAlFlag,
  toggleSelection,
  taskDataById,
  taskTypeId,
  onSelectClearAll,
  dataIdList,
  isActivityReport
) {
  console.log("items regionAndBid= length", _.flatMap(items).length);
  console.log("Geo items=", items);
  const newItems = _.flatten(_.values(items));
  const unitedStatesItems = newItems
    .filter(
      (id) =>
        taskDataById[id].region.includes(",United States") &&
        taskDataById[id].status != 1
    )
    .map((id) => ({
      unitedStates: taskDataById[id].region.replace(",United States", ""),
    }));
  const indiaItems = newItems
    .filter(
      (id) =>
        taskDataById[id].region.includes(",India") &&
        taskDataById[id].status != 1
    )
    .map((id) => ({
      india: taskDataById[id].region.replace(",India", ""),
    }));
  console.log("unitedStatesItems", unitedStatesItems);
  console.log("indiaItems", indiaItems);
  const handleDataByLocation = (list, taskDataById) => {
    console.log("list=", list);
    return list;
  };
  return (
    <div>
      <Card className={classes.card}>
        <CardContent>
          <Typography
            color="textPrimary"
            variant={"h3"}
            className={classes.campaignTitle}
            style={{ maxWidth: "auto" }}
          >
            <b>({_.keys(items).length}) Campaign(s)</b>
          </Typography>
          <Grid container>
            {_.map(items, (list, caption) => (
              <Grid item md={12}>
                {/* todo need to calculate the Campaigns count*/}
                <br />
                <div>
                  <Paper className={classes.tdPaper}>
                    {headerRenderer(caption)}
                    <br />
                    <Fragment>
                      {_.isArray(
                        _.map(handleDataByLocation(list, taskDataById))
                      ) ? (
                        <Grid container spacing={2}>
                          {list.length > 1 ? (
                            <Grid item md={6}>
                              <Table className={classes.table}>
                                <TableHead>
                                  <TableRow>
                                    {/*<CustomTableCellHead>
                                                                    <FormGroup row>
                                                                        <FormControlLabel
                                                                            control={
                                                                                <Checkbox color="secondary"
                                                                                    checked={selectionAlFlag}
                                                                                    onChange={() => onSelectClearAll()}
                                                                                />
                                                                            }
                                                                            label={"Locations"}
                                                                        />
                                                                    </FormGroup>
                                                                </CustomTableCellHead>*/}
                                    <CustomGeoTaskTableCellHead>
                                      Locations
                                    </CustomGeoTaskTableCellHead>
                                    <CustomTableCellHead>
                                      Bid Modifier &nbsp;&nbsp;
                                      {taskTypeId == 23 || taskTypeId == 1
                                        ? icons.upBigArrow
                                        : taskTypeId == 24 || taskTypeId == 21
                                        ? icons.downBigArrow
                                        : null}
                                    </CustomTableCellHead>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  {list
                                    .slice(0, Math.round(list.length) / 2)
                                    .map((id) => (
                                      <Fragment>
                                        <GetTableRowRegionAndBid
                                          toggleSelection={toggleSelection}
                                          classes={classes}
                                          taskDataId={id}
                                          taskDataById={taskDataById}
                                          isActivityReport={isActivityReport}
                                        />
                                      </Fragment>
                                    ))}
                                </TableBody>
                              </Table>
                            </Grid>
                          ) : null}
                          <Grid item md={6}>
                            <Table className={classes.table}>
                              <TableHead>
                                <TableRow>
                                  {/* <CustomTableCellHead>
                                                                    <FormGroup row>
                                                                        <FormControlLabel
                                                                            control={
                                                                                <Checkbox color="secondary"
                                                                                    checked={selectionAlFlag}
                                                                                    onChange={() => onSelectClearAll()}
                                                                                />
                                                                            }
                                                                            label={"Locations"}
                                                                        />
                                                                    </FormGroup>
                                                                </CustomTableCellHead>*/}
                                  <CustomGeoTaskTableCellHead>
                                    Locations
                                  </CustomGeoTaskTableCellHead>
                                  <CustomTableCellHead>
                                    Bid Modifier &nbsp;&nbsp;
                                    {taskTypeId == 23 || taskTypeId == 1
                                      ? icons.upBigArrow
                                      : taskTypeId == 24 || taskTypeId == 21
                                      ? icons.downBigArrow
                                      : null}
                                  </CustomTableCellHead>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {list
                                  .slice(
                                    Math.round(list.length) / 2,
                                    list.length
                                  )
                                  .map((id) => (
                                    <Fragment>
                                      <GetTableRowRegionAndBid
                                        toggleSelection={toggleSelection}
                                        classes={classes}
                                        taskDataId={id}
                                        taskDataById={taskDataById}
                                        isActivityReport={isActivityReport}
                                      />
                                    </Fragment>
                                  ))}
                              </TableBody>
                            </Table>
                          </Grid>
                        </Grid>
                      ) : null}
                    </Fragment>
                  </Paper>
                </div>
              </Grid>
            ))}
          </Grid>
        </CardContent>
      </Card>
    </div>
  );
}

export function withCampaignsAndAdgroups(
  classes,
  description,
  items,
  headerRenderer,
  selectionAlFlag,
  toggleSelection,
  taskDataById,
  taskTypeId,
  onSelectClearAll,
  dataIdList,
  isActivityReport
) {
  return (
    <div>
      <Card className={classes.card}>
        <CardContent>
          <Grid container>
            <Grid item md={12}>
              <Typography
                color="textPrimary"
                variant={"h3"}
                className={classes.campaignTitle}
                style={{ maxWidth: "auto" }}
              >
                <b>({_.keys(items).length}) Campaign(s)</b>
              </Typography>
              <br />
              {_.map(items, (list, caption, i) => (
                <div key={i}>
                  <Paper className={classes.tdPaper}>
                    <br />
                    <Grid container spacing={2}>
                      <Grid item md={12}>
                        <Table className={classes.table}>
                          <TableHead>
                            <TableRow>
                              <CustomTableCellHead>
                                Campaign
                              </CustomTableCellHead>
                              {/*<CustomTableCell>Keywords</CustomTableCell>*/}
                              <CustomTableCellHead>
                                <FormGroup row>
                                  <CustomFormControlLabelHead
                                    control={
                                      isCampaignLevelTaskDataCompleted(
                                        list,
                                        taskDataById
                                      ) ? (
                                        <Checkbox
                                          color="secondary"
                                          checked={true}
                                          icon={
                                            <icons.CheckCircle
                                              className={classes.success}
                                            />
                                          }
                                          checkedIcon={
                                            <icons.CheckCircle
                                              className={classes.success}
                                            />
                                          }
                                          // onChange={() => onSelectClearAll()}
                                          disabled={true}
                                        />
                                      ) : (
                                        <Checkbox
                                          color="secondary"
                                          checked={
                                            handleCampaignLevelSelected(
                                              list,
                                              taskDataById
                                            )
                                          }
                                          onChange={(_, selected) => {
                                              handleCampaignLevelSelection(
                                                toggleSelection,
                                                list,
                                                selected,
                                                taskDataById
                                              );
                                          }}
                                          disabled={isActivityReport}
                                        />
                                      )
                                    }
                                    label={
                                      taskTypeId == 22 || taskTypeId == 46
                                        ? "Keyword"
                                        : "Ad Group"
                                    }
                                  />
                                </FormGroup>
                              </CustomTableCellHead>
                              {/*{(taskTypeId == 22 || taskTypeId == 42) ?
                                                        <CustomTableCellHead align="right">Match
                                                            Type</CustomTableCellHead> : null}*/}
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {_.isArray(_.map(_.values(list))) ? (
                              <Fragment>
                                {_.map(
                                  _.map(_.values(list), (id) => (
                                    <GetTableRowCampaignsAdGroups
                                      toggleSelection={toggleSelection}
                                      classes={classes}
                                      taskDataId={id}
                                      taskDataById={taskDataById}
                                      isActivityReport={isActivityReport}
                                    />
                                  ))
                                )}
                              </Fragment>
                            ) : null}
                          </TableBody>
                        </Table>
                      </Grid>
                    </Grid>
                  </Paper>
                </div>
              ))}
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </div>
  );
}

export function rsaAdCopyGeneration(
  classes,
  description,
  items,
  headerRenderer,
  selectionAlFlag,
  toggleSelection,
  taskDataById,
  taskTypeId,
  onSelectClearAll,
  dataIdList,
  isActivityReport,
  onCheck,
  setSaveStatus
) {
  return (
    <Grid container>
      {_.map(items, (list, caption) => (
        <Grid item md={12}>
          <br />
          <div>
            <Grid container>
              {_.isArray(_.values(list)) && taskTypeId == 51 ? (
                <Fragment>
                  {_.map(_.values(list), (id) => (
                    <Fragment>
                      <GetRSAadCopyRow
                        toggleSelection={toggleSelection}
                        classes={classes}
                        taskDataId={id}
                        taskDataById={taskDataById}
                        onCheck={onCheck}
                        setSaveStatus={setSaveStatus}
                        isActivityReport={isActivityReport}
                      />
                      <br />
                    </Fragment>
                  ))}
                </Fragment>
              ) : null}
            </Grid>{" "}
          </div>
        </Grid>
      ))}
    </Grid>
  );
}

export function adCopyHierarchy(
  classes,
  description,
  items,
  headerRenderer,
  selectionAlFlag,
  toggleSelection,
  taskDataById,
  taskTypeId,
  onSelectClearAll,
  dataIdList,
  isActivityReport
) {
  console.log("items=", items);
  return (
    <Card className={classes.card}>
      <CardContent>
        <Grid container>
          {_.map(items, (list, caption) => (
            <Grid item md={12}>
              {/* todo need to calculate the Campaigns count*/}
              <br />
              <div>
                <Paper className={classes.tdPaper}>
                  {headerRenderer(caption)}
                  <br />
                  <Grid container>
                    {_.isArray(_.values(list)) && taskTypeId == 51 ? (
                      <Fragment>
                        {_.map(_.values(list), (id) => (
                          <Fragment>
                            <Typography color={"textPrimary"}>
                              Ad Group:&nbsp;
                              <b>{taskDataById[id].adGroupName}</b>
                            </Typography>
                            <br />
                            {/*<IconAndName name={taskDataById[id].adGroupName}/>*/}
                            <GetAddAdCopyRow
                              toggleSelection={toggleSelection}
                              classes={classes}
                              taskDataId={id}
                              taskDataById={taskDataById}
                            />
                          </Fragment>
                        ))}
                      </Fragment>
                    ) : null}
                    <Fragment>
                      {_.isArray(_.values(list)) && taskTypeId == 52 ? (
                        <Fragment>
                          {_.map(_.values(list), (array) =>
                            _.map(array, (id) => (
                              <Fragment>
                                <Grid item md={12} style={{ paddingLeft: 10 }}>
                                  <br />
                                  {/*<IconAndName name={taskDataById[id].adGroupName}/>*/}
                                  <Typography color={"textPrimary"}>
                                    Ad Group:&nbsp;
                                    <b>{taskDataById[id].adGroupName}</b>
                                  </Typography>
                                  <br />
                                  <GetAdCopyRow
                                    toggleSelection={toggleSelection}
                                    classes={classes}
                                    taskDataId={id}
                                    taskDataById={taskDataById}
                                    isActivityReport={isActivityReport}
                                  />
                                </Grid>
                              </Fragment>
                            ))
                          )}
                        </Fragment>
                      ) : null}
                    </Fragment>
                    <Fragment>
                      {_.isArray(_.values(list)) &&
                      taskTypeId == 47 &&
                      taskTypeId != 52 &&
                      taskTypeId != 51 ? (
                        <Fragment>
                          {_.map(_.values(list), (array) =>
                            _.map(array, (id) => (
                              <Fragment>
                                <Grid item md={12} style={{ paddingLeft: 10 }}>
                                  <br />
                                  <IconAndName
                                    name={taskDataById[id].adGroupName}
                                  />
                                  <br />
                                  <GetPauseAdCopyHierarchy
                                    toggleSelection={toggleSelection}
                                    classes={classes}
                                    taskDataId={id}
                                    taskDataById={taskDataById}
                                    isActivityReport={isActivityReport}
                                  />
                                </Grid>
                              </Fragment>
                            ))
                          )}
                        </Fragment>
                      ) : null}
                    </Fragment>
                  </Grid>
                </Paper>
              </div>
            </Grid>
          ))}
        </Grid>
      </CardContent>
    </Card>
  );
}

const CustomTableCell = withStyles((theme) => ({
  body: {
    background: "rgba(255, 255, 255, 0.6)",
    border: "1px solid #E3E6F4",
    boxSizing: "border-box",
    color: theme.palette.text.primary,
    fontWeight: "bold",
    fontSize: theme.typography.pxToRem(16),
    fontFamily: "Lato",
    padding: "4px 24px 4px 24px",
    [theme.breakpoints.down("lg")]: {
      wordBreak: "break-all",
      padding: 5,
      fontSize: theme.typography.pxToRem(15),
    },
  },
  // label
}))(TableCell);

const CustomChip = withStyles((theme) => ({
  label: {
    color: theme.palette.text.primary,
  },
  // label
}))(Chip);
const CustomTableCellSuccess = withStyles((theme) => ({
  body: {
    background: "rgba(255, 255, 255, 0.6)",
    border: "1px solid #E3E6F4",
    boxSizing: "border-box",
    color: theme.palette.success1.main,
    fontWeight: "bold",
    fontSize: theme.typography.pxToRem(16),
    fontFamily: "Lato",
    padding: "4px 24px 4px 24px",
  },
}))(TableCell);
const CustomTableCellFailed = withStyles((theme) => ({
  body: {
    background: "rgba(255, 255, 255, 0.6)",
    border: "1px solid #E3E6F4",
    boxSizing: "border-box",
    color: theme.palette.error.main,
    fontWeight: "bold",
    fontSize: theme.typography.pxToRem(16),
    fontFamily: "Lato",
    padding: "4px 24px 4px 24px",
  },
  // label
}))(TableCell);
const CustomTableCellHead = withStyles((theme) => ({
  head: {
    // width:"40%",
    background: "rgba(242, 243, 249, 0.6)",
    border: "1px solid #E3E6F4",
    boxSizing: "border-box",
    borderRadius: "10px 10px 0px 0px",
    color: theme.palette.text.primary,
    fontWeight: "500",
    fontSize: theme.typography.pxToRem(14),
    fontFamily: "Lato",
    [theme.breakpoints.down("xl")]: {
      padding: 5,
    },
  },
  // label
}))(TableCell);
const CustomGeoTaskTableCellHead = withStyles((theme) => ({
  head: {
    width: "60%",
    background: "rgba(242, 243, 249, 0.6)",
    border: "1px solid #E3E6F4",
    boxSizing: "border-box",
    borderRadius: "10px 10px 0px 0px",
    color: theme.palette.text.primary,
    fontWeight: "500",
    fontSize: theme.typography.pxToRem(14),
    fontFamily: "Lato",
  },
  // label
}))(TableCell);
const CustomKeywordsTableCellHead = withStyles((theme) => ({
  head: {
    width: "50%",
    background: "rgba(242, 243, 249, 0.6)",
    border: "1px solid #E3E6F4",
    boxSizing: "border-box",
    borderRadius: "10px 10px 0px 0px",
    color: theme.palette.text.primary,
    fontWeight: "500",
    fontSize: theme.typography.pxToRem(14),
    fontFamily: "Lato",
    [theme.breakpoints.down("xl")]: {
      padding: 5,
    },
  },
  // label
}))(TableCell);
const HoulryTableCellHead = withStyles((theme) => ({
  head: {
    background: "rgba(242, 243, 249, 0.6)",
    border: "1px solid #E3E6F4",
    boxSizing: "border-box",
    borderRadius: "10px 10px 0px 0px",
    color: theme.palette.text.primary,
    fontWeight: "500",
    fontSize: theme.typography.pxToRem(14),
    fontFamily: "Lato",
    padding: 10,
  },
  // label
}))(TableCell);
const CustomFormControlLabel = withStyles((theme) => ({
  label: {
    color: theme.palette.text.primary,
    fontSize: theme.typography.pxToRem(16),
    fontWeight: "bold",
    fontFamily: "Lato",
  },
}))(FormControlLabel);
const HourlyFormControlLabel = withStyles((theme) => ({
  root: {
    broder: "1px solid #E3E6F4",
    "& > span": {
      padding: 0,
    },
  },
  label: {
    color: theme.palette.text.primary,
    fontSize: theme.typography.pxToRem(16),
    fontWeight: "bold",
    fontFamily: "Lato",
  },
}))(FormControlLabel);

function GetTableRow({
  classes,
  taskDataId,
  taskDataById,
  toggleSelection,
  isActivityReport,
}) {
  const taskData = taskDataById[taskDataId];
  var currency = store.getState().perf.currency;
  var currencySymbol = getSymbolFromCurrency(currency);
  // console.log("taskData", taskData);
  // console.log("taskDataId", taskDataId);
  return (
    <TableRow className={classes.row} key={taskDataId}>
      {taskData.taskTypeId != 42 ? (
        <CustomTableCell component="th" scope="row">
          {taskData.adGroupName}
        </CustomTableCell>
      ) : null}
      {taskData.taskTypeId == 13 ? (
        <CustomTableCell component="th" scope="row">
          <Typography variant={"body1"} color={"textPrimary"}>
            <b>{taskData.keywordText}</b>
          </Typography>
        </CustomTableCell>
      ) : null}
      <CustomTableCell align="left">
        <FormGroup row>
          <CustomFormControlLabel
            control={
              taskData.status == 1 || taskData.status == -1 ? (
                <Checkbox
                  color="secondary"
                  checked={taskData.selected}
                  icon={
                    taskData.status == 1 ? (
                      <icons.CheckCircle className={classes.success} />
                    ) : (
                      <Warning className={classes.warningIconStyle} />
                    )
                  }
                  // onChange={(_, selected) => toggleSelection([taskDataId], selected)}
                  disabled={true}
                />
              ) : (
                <Checkbox
                  color="secondary"
                  checked={taskData.selected}
                  onChange={(_, selected) =>
                    toggleSelection([taskDataId], selected)
                  }
                  disabled={isActivityReport ? true : false}
                />
              )
            }
            // label={taskData.keywordText + `(${taskData.taskDataId})`}
            /*label={<IconText text={taskData.keywordText}
                                         keywordMatchType={taskData.keywordMatchType}
                                         keywordUrl={taskData.keywordUrl}/>}*/
            label={
              taskData.taskTypeId == 13 ? (
                <span>
                  <Typography variant={"body2"} color={"textPrimary"}>
                    <a href={taskData.keywordUrl} target={"_blank"}>
                      {newConciseURL(taskData.keywordUrl)}
                    </a>
                    &nbsp;
                  </Typography>
                  {/*<Typography variant={"caption"}>
                            {taskData.keywordText}
                        </Typography>*/}
                </span>
              ) : (
                <IconText
                  text={taskData.keywordText}
                  keywordMatchType={taskData.keywordMatchType}
                  keywordUrl={taskData.keywordUrl}
                />
              )
            }
          />
        </FormGroup>
        {taskData.analysisTypeId == 23 ? (
          <Typography align={"right"}>
            <CustomChip
              style={{ background: "#FFFBF0" }}
              icon={
                <i
                  className={["fa fa-google", classes.chip]}
                  aria-hidden="true"
                ></i>
              }
              label="Google Recommended"
            />
          </Typography>
        ) : null}
      </CustomTableCell>
      {taskData.taskTypeId != 47 &&
      taskData.taskTypeId != 13 &&
      taskData.taskTypeId != 2 &&
      taskData.taskTypeId != 38 &&
      taskData.taskTypeId != 5 &&
      taskData.taskTypeId != 7 &&
      taskData.taskTypeId != 41 &&
      taskData.taskTypeId != 42 &&
      taskData.taskTypeId != 14 &&
      taskData.taskTypeId != 48 ? (
        <CustomTableCell align="left">
          {currencySymbol}
          {taskData.maxCpc}
        </CustomTableCell>
      ) : null}
    </TableRow>
  );
}

function GetTableRowWithAdgroupsAndKeywordsOldNewBid({
  classes,
  taskDataId,
  taskDataById,
  toggleSelection,
  isActivityReport,
}) {
  const taskData = taskDataById[taskDataId];
  var currency = store.getState().perf.currency;
  var currencySymbol = getSymbolFromCurrency(currency);
  return (
    <TableRow className={classes.row} key={taskDataId}>
      <CustomTableCell component="th" scope="row">
        {taskData.adGroupName}
      </CustomTableCell>
      <CustomTableCell align="left">
        <FormGroup row>
          <CustomFormControlLabel
            control={
              taskData.status == 1 || taskData.status == -1 ? (
                <Checkbox
                  color="secondary"
                  checked={taskData.selected}
                  icon={
                    taskData.status == 1 ? (
                      <icons.CheckCircle className={classes.success} />
                    ) : (
                      <Warning className={classes.warningIconStyle} />
                    )
                  }
                  // onChange={(_, selected) => toggleSelection([taskDataId], selected)}
                  disabled={true}
                />
              ) : (
                <Checkbox
                  color="secondary"
                  checked={taskData.selected}
                  onChange={(_, selected) =>
                    toggleSelection([taskDataId], selected)
                  }
                  disabled={isActivityReport ? true : false}
                />
              )
            }
            label={
              <IconText
                text={taskData.keywordText}
                keywordMatchType={taskData.keywordMatchType}
              />
            }
          />
        </FormGroup>
      </CustomTableCell>
      {/* <CustomTableCell align="left">
                {taskData.keywordMatchType}
            </CustomTableCell>*/}
      <CustomTableCell align="left">
        <Grid container alignItems={"center"} spacing={2}>
          {/*!isNaN(stat.kpiValue) ? stat.kpiValue.toFixed(2)*/}
          <Grid item>
            {currencySymbol}
            {!isNaN(taskData.maxCpc) ? taskData.maxCpc.toFixed(2) : 0}
          </Grid>
          <Grid item>{icons.leftArrowB}</Grid>
          <Grid item>
            <Typography
              style={{ color: "#000", paddingTop: 5 }}
              variant={"caption"}
            >
              {currencySymbol}
              {!isNaN(taskData.currentMaxCpc)
                ? taskData.currentMaxCpc.toFixed(2)
                : 0}
            </Typography>
          </Grid>
        </Grid>
      </CustomTableCell>
      {/*<CustomTableCell align="left">*/}

      {/*</CustomTableCell>*/}
    </TableRow>
  );
}

function GetTableRowProductGroupDimCPC({
  classes,
  taskDataId,
  taskDataById,
  toggleSelection,
  isActivityReport,
}) {
  const taskData = taskDataById[taskDataId];
  var currency = store.getState().perf.currency;
  var currencySymbol = getSymbolFromCurrency(currency);
  return (
    <TableRow className={classes.row} key={taskDataId}>
      <CustomTableCell component="th" scope="row">
        {taskData.adGroupName}
      </CustomTableCell>
      <CustomTableCell align="left">
        <FormGroup row>
          <CustomFormControlLabel
            control={
              taskData.status == 1 || taskData.status == -1 ? (
                <Checkbox
                  color="secondary"
                  checked={taskData.selected}
                  icon={
                    taskData.status == 1 ? (
                      <icons.CheckCircle className={classes.success} />
                    ) : (
                      <Warning className={classes.warningIconStyle} />
                    )
                  }
                  // onChange={(_, selected) => toggleSelection([taskDataId], selected)}
                  disabled={true}
                />
              ) : (
                <Checkbox
                  color="secondary"
                  checked={taskData.taskTypeId == 28 || taskData.selected}
                  onChange={(_, selected) => {
                    if(taskData.taskTypeId !== 28){
                      toggleSelection([taskDataId], selected);
                    }
                  }}
                  disabled={isActivityReport ? true : false}
                />
              )
            }
            label={
              taskData.taskTypeId == 29 || taskData.taskTypeId == 30 ? (
                taskData.productGroup
              ) : (
                <IconText
                  text={taskData.productGroup}
                  productType={taskData.productDimension}
                />
              )
            }
          />
        </FormGroup>
      </CustomTableCell>
      {/*  {(taskData.taskTypeId == 29 || taskData.taskTypeId == 30) ?
                null : <CustomTableCell align="left">{taskData.productDimension}</CustomTableCell>}*/}
      {/*<CustomTableCell align="left">{taskData.currentMaxCpc}</CustomTableCell>*/}
      <CustomTableCell align="left">
        <Grid container alignItems={"center"} spacing={2}>
          <Grid item>
            {currencySymbol}
            {!isNaN(taskData.maxCpc) ? taskData.maxCpc.toFixed(2) : 0}
          </Grid>
          <Grid item>{icons.leftArrowB}</Grid>
          <Grid item>
            <Typography
              style={{ color: "#000", paddingTop: 5 }}
              variant={"caption"}
            >
              {currencySymbol}
              {!isNaN(taskData.currentMaxCpc)
                ? taskData.currentMaxCpc.toFixed(2)
                : 0}
            </Typography>
          </Grid>
        </Grid>
      </CustomTableCell>
    </TableRow>
  );
}

function GetTableRowAddRemoveProductGroups({
  classes,
  taskDataId,
  taskDataById,
  toggleSelection,
  isActivityReport,
}) {
  const taskData = taskDataById[taskDataId];
  return (
    <TableRow className={classes.row} key={taskDataId}>
      <CustomTableCell component="th" scope="row">
        {taskData.adGroupName}
      </CustomTableCell>
      <CustomTableCell align="left">
        <FormGroup row>
          <CustomFormControlLabel
            control={
              taskData.status == 1 || taskData.status == -1 ? (
                <Checkbox
                  color="secondary"
                  checked={taskData.selected}
                  icon={
                    taskData.status == 1 ? (
                      <icons.CheckCircle className={classes.success} />
                    ) : (
                      <Warning className={classes.warningIconStyle} />
                    )
                  }
                  // onChange={(_, selected) => toggleSelection([taskDataId], selected)}
                  disabled={true}
                />
              ) : (
                <Checkbox
                  color="secondary"
                  checked={taskData.selected}
                  onChange={(_, selected) =>
                    toggleSelection([taskDataId], selected)
                  }
                  disabled={isActivityReport ? true : false}
                />
              )
            }
            label={taskData.productGroup}
          />
        </FormGroup>
      </CustomTableCell>
    </TableRow>
  );
}

function GetTableRowCampaignNameTypeBudget({
  classes,
  taskDataId,
  taskDataById,
  toggleSelection,
  isActivityReport,
}) {
  const taskData = taskDataById[taskDataId];
  var currency = store.getState().perf.currency;
  var currencySymbol = getSymbolFromCurrency(currency);
  console.log("taskData=", taskData);
  return (
    <TableRow className={classes.row} key={taskDataId}>
      {/*   <CustomTableCell align="left">
                <Grid container alignItems={"center"} spacing={2}>
                    <Grid item>{taskData.budget}</Grid>
                    <Grid item>{icons.leftArrowB}</Grid>
                    <Grid item><Typography style={{color: "#000"}} variant={"caption"}>
                        {taskData.oldBudget}</Typography></Grid>
                </Grid>
            </CustomTableCell>*/}
      {/*  <CustomTableCell component="th" scope="row">
                {taskData.oldBudget}
            </CustomTableCell>*/}
      <CustomTableCell align="left">
        <FormGroup row>
          <CustomFormControlLabel
            control={
              taskData.status == 1 || taskData.status == -1 ? (
                <Checkbox
                  color="secondary"
                  checked={taskData.selected}
                  icon={
                    taskData.status == 1 ? (
                      <icons.CheckCircle className={classes.success} />
                    ) : (
                      <Warning className={classes.warningIconStyle} />
                    )
                  }
                  // onChange={(_, selected) => toggleSelection([taskDataId], selected)}
                  disabled={true}
                />
              ) : (
                <Checkbox
                  color="secondary"
                  checked={taskData.selected}
                  onChange={(_, selected) =>
                    toggleSelection([taskDataId], selected)
                  }
                  disabled={isActivityReport ? true : false}
                />
              )
            }
            label={
              <Grid container alignItems={"center"} spacing={2}>
                <Grid item>
                  {currencySymbol}
                  {taskData.budget}
                </Grid>
                <Grid item>{icons.leftArrowB}</Grid>
                <Grid item>
                  <Typography
                    style={{ color: "#000", paddingTop: 5 }}
                    variant={"caption"}
                  >
                    {currencySymbol}
                    {taskData.oldBudget}
                  </Typography>
                </Grid>
              </Grid>
            }
          />
        </FormGroup>
      </CustomTableCell>
    </TableRow>
  );
}

function GetTableRowCampaignNameTypeBidLimit({
  classes,
  taskDataId,
  taskDataById,
  toggleSelection,
  isActivityReport,
}) {
  const taskData = taskDataById[taskDataId];
  var currency = store.getState().perf.currency;
  var currencySymbol = getSymbolFromCurrency(currency);
  console.log("taskData=", taskData);
  return (
    <TableRow className={classes.row} key={taskDataId}>
      <CustomTableCell align="left">
        <FormGroup row>
          <CustomFormControlLabel
            control={
              taskData.status == 1 || taskData.status == -1 ? (
                <Checkbox
                  color="secondary"
                  checked={taskData.selected}
                  icon={
                    taskData.status == 1 ? (
                      <icons.CheckCircle className={classes.success} />
                    ) : (
                      <Warning className={classes.warningIconStyle} />
                    )
                  }
                  // onChange={(_, selected) => toggleSelection([taskDataId], selected)}
                  disabled={true}
                />
              ) : (
                <Checkbox
                  color="secondary"
                  checked={taskData.selected}
                  onChange={(_, selected) =>
                    toggleSelection([taskDataId], selected)
                  }
                  disabled={isActivityReport ? true : false}
                />
              )
            }
            label={
              <Grid container alignItems={"center"} spacing={2}>
                <Grid item>
                  {currencySymbol}
                  {taskData.maxCpc}
                </Grid>
              </Grid>
            }
          />
        </FormGroup>
      </CustomTableCell>
    </TableRow>
  );
}

function GetTableRowCampaignNameBudget({
  classes,
  taskDataId,
  taskDataById,
  toggleSelection,
  isActivityReport,
}) {
  const taskData = taskDataById[taskDataId];
  console.log("taskData=", taskData);
  return (
    <TableRow className={classes.row} key={taskDataId}>
      <CustomTableCell component="th" scope="row">
        {taskData.campaignName}
      </CustomTableCell>
      <CustomTableCell align="left">
        <FormGroup row>
          <CustomFormControlLabel
            // control={
            //     <Checkbox color="secondary"
            //         checked={taskData.selected}
            //         onChange={(_, selected) => toggleSelection([taskDataId], selected)}
            //         disabled={isActivityReport ? true : false}
            //     />
            // }
            control={
              taskData.status == 1 || taskData.status == -1 ? (
                <Checkbox
                  color="secondary"
                  checked={taskData.selected}
                  icon={
                    taskData.status == 1 ? (
                      <icons.CheckCircle className={classes.success} />
                    ) : (
                      <Warning className={classes.warningIconStyle} />
                    )
                  }
                  // onChange={(_, selected) => toggleSelection([taskDataId], selected)}
                  disabled={true}
                />
              ) : (
                <Checkbox
                  color="secondary"
                  checked={taskData.selected}
                  onChange={(_, selected) =>
                    toggleSelection([taskDataId], selected)
                  }
                  disabled={isActivityReport ? true : false}
                />
              )
            }
            label={taskData.budget}
          />
        </FormGroup>
      </CustomTableCell>
    </TableRow>
  );
}

function IconText(props) {
  return (
    <Grid container spacing={2} alignItems={"center"}>
      <Grid item>{props.text}</Grid>
      <Grid item>
        <CustomToolTip>
          {props.keywordMatchType ? (
            <Typography
              color={"textPrimary"}
              variant={"caption"}
              style={{ textTransform: "capitalize" }}
            >
              Match Type - {props.keywordMatchType}
            </Typography>
          ) : null}
          {props.campaignType ? (
            <Typography
              color={"textPrimary"}
              variant={"caption"}
              style={{ textTransform: "capitalize" }}
            >
              Campaign Type - {props.campaignType}
            </Typography>
          ) : null}
          {props.productType ? (
            <Typography
              color={"textPrimary"}
              variant={"caption"}
              style={{ textTransform: "capitalize" }}
            >
              {props.productType}
            </Typography>
          ) : null}
          {props.keywordUrl ? (
            <Typography variant={"body2"} color={"textPrimary"}>
              <a href={props.keywordUrl} target={"_blank"}>
                {conciseURL(props.keywordUrl)}
              </a>
            </Typography>
          ) : null}
        </CustomToolTip>
      </Grid>
    </Grid>
  );
}

IconText.propTypes = { taskData: PropTypes.any };

function GetTableRowKeywordUrls({
  classes,
  taskDataId,
  taskDataById,
  toggleSelection,
  isActivityReport,
}) {
  const taskData = taskDataById[taskDataId];
  return (
    <TableRow className={classes.row} key={taskDataId}>
      <CustomTableCell component="th" scope="row">
        {taskData.adGroupName}
      </CustomTableCell>
      {/*<CustomTableCell align="left">
                {taskData.keywordText}
            </CustomTableCell>*/}
      <CustomTableCell align="left">
        <FormGroup row>
          <CustomFormControlLabel
            control={
              taskData.status == 1 || taskData.status == -1 ? (
                <Checkbox
                  color="secondary"
                  checked={taskData.selected}
                  icon={
                    taskData.status == 1 ? (
                      <icons.CheckCircle className={classes.success} />
                    ) : (
                      <Warning className={classes.warningIconStyle} />
                    )
                  }
                  onChange={(_, selected) =>
                    toggleSelection([taskDataId], selected)
                  }
                  disabled={true}
                />
              ) : (
                <Checkbox
                  color="secondary"
                  checked={taskData.selected}
                  onChange={(_, selected) =>
                    toggleSelection([taskDataId], selected)
                  }
                  disabled={isActivityReport ? true : false}
                />
              )
            }
            label={
              <IconText
                text={taskData.keywordText}
                keywordMatchType={taskData.keywordMatchType}
                keywordUrl={taskData.keywordUrl}
              />
            }
          />
        </FormGroup>
      </CustomTableCell>
      {/* <CustomTableCell align="left">
                {taskData.keywordMatchType}
            </CustomTableCell>*/}
    </TableRow>
  );
}

function GetTableRowAdgroupBidModifiers({
  classes,
  taskDataId,
  taskDataById,
  toggleSelection,
  isActivityReport,
}) {
  const taskData = taskDataById[taskDataId];
  console.log("taskData=", taskData);
  const { taskTypeId } = taskData;
  return (
    <TableRow className={classes.row} key={taskDataId}>
      <CustomTableCell component="th" scope="row">
        {taskData.adGroupName}
      </CustomTableCell>
      <CustomTableCell align="left">
        <FormGroup row>
          <CustomFormControlLabel
            control={
              taskData.status == 1 || taskData.status == -1 ? (
                <Checkbox
                  color="secondary"
                  checked={taskData.selected}
                  icon={
                    taskData.status == 1 ? (
                      <icons.CheckCircle className={classes.success} />
                    ) : (
                      <Warning className={classes.warningIconStyle} />
                    )
                  }
                  onChange={(_, selected) =>
                    toggleSelection([taskDataId], selected)
                  }
                  disabled={true}
                />
              ) : (
                <Checkbox
                  color="secondary"
                  checked={taskData.selected}
                  onChange={(_, selected) =>
                    toggleSelection([taskDataId], selected)
                  }
                  disabled={isActivityReport ? true : false}
                />
              )
            }
            label={`${taskData.bidModifier}%`}
          />
        </FormGroup>
      </CustomTableCell>
    </TableRow>
  );
}

function GetTableRowRegionAndBid({
  classes,
  taskDataId,
  taskDataById,
  toggleSelection,
  isActivityReport,
}) {
  const taskData = taskDataById[taskDataId];
  console.log("taskData=", taskData);
  const { taskTypeId } = taskData;
  return (
    <TableRow className={classes.row} key={taskDataId}>
      <CustomTableCell align="left">
        <FormGroup row>
          <CustomFormControlLabel
            control={
              taskData.status == 1 || taskData.status == -1 ? (
                <Checkbox
                  color="secondary"
                  checked={taskData.selected}
                  icon={
                    taskData.status == 1 ? (
                      <icons.CheckCircle className={classes.success} />
                    ) : (
                      <Warning className={classes.warningIconStyle} />
                    )
                  }
                  checkedIcon={
                    taskData.status == 1 ? (
                      <icons.CheckCircle className={classes.success} />
                    ) : (
                      <Warning className={classes.warningIconStyle} />
                    )
                  }
                  // onChange={(_, selected) => toggleSelection([taskDataId], selected)}
                  disabled={true}
                />
              ) : (
                <Checkbox
                  color="secondary"
                  checked={taskData.selected}
                  onChange={(_, selected) =>
                    toggleSelection([taskDataId], selected)
                  }
                  disabled={isActivityReport ? true : false}
                />
              )
            }
            label={`${
              taskData.region.includes(",United States")
                ? taskData.region.replace(",United States", "")
                : taskData.region
            }`}
          />
        </FormGroup>
      </CustomTableCell>
      <CustomTableCell component="th" scope="row">
        {taskData.bidModifier}%
      </CustomTableCell>
    </TableRow>
  );
}

function GetTableRowCampaignsAdGroups({
  classes,
  taskDataId,
  taskDataById,
  toggleSelection,
  isActivityReport,
}) {
  const taskData = taskDataById[taskDataId];
  // console.log("taskData=", taskData);
  return (
    <TableRow className={classes.row} key={taskDataId}>
      <CustomTableCell component="th" scope="row">
        {/*{taskData.campaignName}*/}
        <IconText
          text={taskData.campaignName}
          campaignType={taskData.campaignType}
        />
      </CustomTableCell>
      <CustomTableCell align="left">
        <FormGroup row>
          <CustomFormControlLabel
            control={
              taskData.status == 1 || taskData.status == -1 ? (
                <Checkbox
                  color="secondary"
                  checked={taskData.selected}
                  icon={
                    taskData.status == 1 ? (
                      <icons.CheckCircle className={classes.success} />
                    ) : (
                      <Warning className={classes.warningIconStyle} />
                    )
                  }
                  // onChange={(_, selected) => toggleSelection([taskDataId], selected)}
                  disabled={true}
                />
              ) : (
                <Checkbox
                  color="secondary"
                  checked={taskData.selected}
                  onChange={(_, selected) => {
                      toggleSelection([taskDataId], selected);
                  }}
                  disabled={isActivityReport}
                />
              )
            }
            label={
              taskData.taskTypeId == 22 || taskData.taskTypeId == 46 ? (
                <IconText
                  text={taskData.keywordText}
                  keywordMatchType={taskData.keywordMatchType}
                />
              ) : (
                taskData.adGroupName
              )
            }
          />
        </FormGroup>
      </CustomTableCell>
      {/*{(taskData.taskTypeId == 22 || taskData.taskTypeId == 42) &&
            <CustomTableCell align="left">{taskData.keywordMatchType}</CustomTableCell>}*/}
    </TableRow>
  );
}
function GetRSAadCopyRow({
  classes,
  taskDataId,
  taskDataById,
  isActivityReport,
  onCheck,
  setSaveStatus,
  selectAllCheck,
  setAllAdCopies,
}) {
  const d = taskDataById[taskDataId];
  const { adCopy, refAdCopy } = d;
  const taskDataId1 = taskDataId[0];
  const task =
    d.taskDataList &&
    d.taskDataList.find((task) => task.taskDataId === taskDataId1);
  const status = d.status;
  const domainUrl = getDomainUrl(refAdCopy);
  return (
    <Fragment>
      <AdCopyGeneration
        campaignName={d.campaignName}
        adgroupName={d.adGroupName}
        campaignId={d.campaignId}
        adgroupId={d.adGroupId}
        taskStatus={status}
        taskDataId={taskDataId1}
        onCheck={onCheck}
        setSaveStatus={setSaveStatus}
        isActivityReport={isActivityReport}
      />
    </Fragment>
  );
}

function GetAddAdCopyRow({
  classes,
  taskDataId,
  taskDataById,
  toggleSelection,
}) {
  const d = taskDataById[taskDataId];
  const { adCopy, refAdCopy } = d;
  const domainUrl = getDomainUrl(refAdCopy);
  const successColor = "#6FCF97";
  return (
    <Fragment>
      {d.status == 1 ? (
        <Grid item className={"fullWidth"} md={12}>
          {adCopy && adCopy.completedAs == "DIY" && (
            <AdCopyPreview
              headLine1={adCopy.details.headLine1}
              headLine2={adCopy.details.headLine2}
              headLine3={adCopy.details.headLine3}
              domainUrl={domainUrl}
              path1={adCopy.details.path1}
              path2={adCopy.details.path2}
              description1={adCopy.details.description1}
              description2={adCopy.details.description2}
            />
          )}
          {adCopy && adCopy.completedAs == "ServicePack" && (
            <Grid
              container
              alignItems={"center"}
              spacing={2}
              style={{ padding: 10 }}
            >
              <Grid item>
                <icons.CheckCircle style={{ color: successColor }} />
              </Grid>
              <Grid item>
                {" "}
                <Typography
                  color={"textPrimary"}
                  variant={"body1"}
                  gutterBottom
                >
                  <b>Service Requested</b>
                </Typography>
              </Grid>
            </Grid>
          )}
        </Grid>
      ) : (
        <Grid item className={"fullWidth"}>
          <AdCopyHierarchy taskData={d} />
        </Grid>
      )}
    </Fragment>
  );
}

function Schedule2({ taskDataList, classes }) {
  console.log("taskDataList Horuly=", taskDataList);
  let grouped = multiGroupBy(taskDataList, ["dayOfWeek", "startHour"]);
  let days = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
  ];
  let hours = _.chain(taskDataList).map("startHour").uniq().value();
  console.log("hours values are = ", hours);
  console.log("grouped values are = ", grouped);
  let hourLabels = _.chain(taskDataList)
    .map(({ startHour, endHour }) => `${startHour} - ${endHour}`)
    .uniq()
    .value();
  const TableRow = TableRowAutoHeight;
  const TableCell = TableCellSchedule;
  return (
    <Paper className={classes.hourlyPaper1} style={{ boxShadow: "unset" }}>
      {
        <Table className={classes.hourlyTable1}>
          <TableHead>
            <TableRow>
              <HoulryTableCellHead align={"center"}>Day</HoulryTableCellHead>
              {hourLabels.map((hourLabel) => (
                <HoulryTableCellHead numeric key={hourLabel}>
                  <Typography noWrap>{hourLabel}</Typography>
                </HoulryTableCellHead>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {days.map((day) => (
              <TableRow key={day}>
                <CustomTableCell align={"center"}>{day}</CustomTableCell>
                {hours.map((hour) => (
                  <Fragment>
                    {day in grouped &&
                      hour in grouped[day] &&
                      grouped[day][hour][0].bidModifier > 0 && (
                        <CustomTableCellSuccess align={"right"} key={hour}>
                          {day in grouped &&
                            hour in grouped[day] &&
                            grouped[day][hour][0].bidModifier}
                        </CustomTableCellSuccess>
                      )}
                    {day in grouped &&
                      hour in grouped[day] &&
                      grouped[day][hour][0].bidModifier < 0 && (
                        <CustomTableCellFailed align={"right"} key={hour}>
                          {day in grouped &&
                            hour in grouped[day] &&
                            grouped[day][hour][0].bidModifier}
                        </CustomTableCellFailed>
                      )}
                    {day in grouped &&
                      hour in grouped[day] &&
                      grouped[day][hour][0].bidModifier == 0 && (
                        <CustomTableCell align={"right"} key={hour}>
                          {day in grouped &&
                            hour in grouped[day] &&
                            grouped[day][hour][0].bidModifier}
                        </CustomTableCell>
                      )}
                    {!(day in grouped) && (
                      <CustomTableCell align={"right"} key={hour}>
                        {}
                      </CustomTableCell>
                    )}
                  </Fragment>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      }
    </Paper>
  );
}

export function GetAdCopyRow({
  classes,
  taskDataId,
  taskDataById,
  toggleSelection,
  isActivityReport,
}) {
  const d = taskDataById[taskDataId];
  // const {adCopyContent} = d;
  console.log("analyid check td=", d);
  const domainUrl = getDomainUrl(d.adCopyContent);
  return (
    <Grid container alignItems={"flex-start"} justifyContent={"flex-start"}>
      <Grid item md={1}>
        <FormGroup row style={{ padding: 16 }}>
          <CustomFormControlLabel
            control={
              d.status == 1 || d.status == -1 ? (
                <Checkbox
                  color="secondary"
                  checked={d.selected}
                  icon={
                    d.status == 1 ? (
                      <icons.CheckCircle className={classes.success} />
                    ) : (
                      <Warning className={classes.warningIconStyle} />
                    )
                  }
                  checkedIcon={
                    <icons.CheckCircle className={classes.success} />
                  }
                  onChange={(_, selected) =>
                    toggleSelection([taskDataId], selected)
                  }
                  disabled={true}
                />
              ) : (
                <Checkbox
                  color="secondary"
                  checked={d.selected}
                  onChange={(_, selected) =>
                    toggleSelection([taskDataId], selected)
                  }
                  disabled={isActivityReport ? true : false}
                />
              )
            }
          />
        </FormGroup>
      </Grid>
      <Grid item md={11}>
        {d.adCopyContent != null ? (
          <AdCopyPreview
            headLine1={d.adCopyContent.headLine1}
            headLine2={d.adCopyContent.headLine2}
            headLine3={d.adCopyContent.headLine3}
            path1={d.adCopyContent.path1}
            path2={d.adCopyContent.path2}
            domainUrl={domainUrl}
            description1={d.adCopyContent.description1}
            description2={d.adCopyContent.description2}
            finalUrl={d.adCopyContent.finalUrl}
            gleRecommendation={d.analysisTypeId == 23}
          />
        ) : null}
      </Grid>
    </Grid>
  );
}

export function GetPauseAdCopyHierarchy({
  classes,
  taskDataId,
  taskDataById,
  toggleSelection,
  isActivityReport,
}) {
  const d = taskDataById[taskDataId];
  const { refAdCopy } = d;
  console.log("taskDataAdCopyHierarchy=", refAdCopy);
  const domainUrl = getDomainUrl(refAdCopy);

  return (
    <Grid container>
      <Grid item>
        <FormGroup row style={{ padding: 16 }}>
          <CustomFormControlLabel
            control={
              d.status == 1 ? (
                <Checkbox
                  color="secondary"
                  checked={d.selected}
                  icon={<icons.CheckCircle className={classes.success} />}
                  checkedIcon={
                    <icons.CheckCircle className={classes.success} />
                  }
                  onChange={(_, selected) =>
                    toggleSelection([taskDataId], selected)
                  }
                  disabled={true}
                />
              ) : (
                <Checkbox
                  color="secondary"
                  checked={d.selected}
                  onChange={(_, selected) =>
                    toggleSelection([taskDataId], selected)
                  }
                  disabled={isActivityReport ? true : false}
                />
              )
            }
          />
        </FormGroup>
      </Grid>
      <Grid item>
        {refAdCopy != null ? (
          <AdCopyPreview
            headLine1={refAdCopy.headlinePart1}
            headLine2={refAdCopy.headlinePart2}
            path1={refAdCopy.path1}
            path2={refAdCopy.path2}
            domainUrl={domainUrl}
            description1={refAdCopy.description}
          />
        ) : null}
      </Grid>
    </Grid>
  );
}

export function IncreaseOrDecreaseTROAS(
  classes,
  description,
  items,
  headerRenderer,
  selectionAlFlag,
  toggleSelection,
  taskDataById,
  taskTypeId,
  onSelectClearAll,
  dataIdList,
  isActivityReport
) {
  return (
    <div>
      <Card className={classes.card}>
        <CardContent>
          <Grid container direction="row" rowSpacing={3}>
            <Grid item md={12}>
              <Typography
                color="textPrimary"
                variant={"h3"}
                className={classes.campaignTitle}
                style={{ maxWidth: "auto" }}
              >
                <b>({_.keys(items).length}) Campaign(s)</b>
              </Typography>
            </Grid>
            {_.map(items, (id) => (
              <Grid item sm={12} xs={12} md={12} lg={6}>
                <Paper className={classes.tdPaper}>
                  <IncreaseOrDecreaseTarget
                    taskTypeId={taskTypeId}
                    taskDataById={taskDataById}
                    name={taskDataById[id].campaignName}
                    caption={
                      <NewTaskTypeTitle
                        type="Campaign"
                        value={taskDataById[id].campaignType}
                      />
                    }
                    isActivityReport={isActivityReport}
                    id={id}
                    toggleSelection={toggleSelection}
                    currTarget={taskDataById[id].currentTargetValue}
                    recommendedTarget={taskDataById[id].updatedTargetValue}
                    classes={classes}
                  />
                </Paper>
              </Grid>
            ))}
          </Grid>
        </CardContent>
      </Card>
    </div>
  );
}

export function UpgradeBiddingStrategy(
  classes,
  description,
  items,
  headerRenderer,
  selectionAlFlag,
  toggleSelection,
  taskDataById,
  taskTypeId,
  onSelectClearAll,
  dataIdList,
  isActivityReport
) {
  function getRecommendationStrategy(currentTaskData) {
    switch (taskTypeId) {
      case TaskTypeEnum.UPGRADE_MANUAL_BID_TO_TARGET_ROAS.taskTypeId:
        if (currentTaskData.campaignType === "Search") {
          return "Maximize Conversion Value";
        } else if (currentTaskData.campaignType === "Shopping") {
          return "Target ROAS";
        } else {
          return "Maximize Conversion Value";
        }
      case TaskTypeEnum.UPGRADE_MANUAL_BID_TO_TARGET_CPA.taskTypeId:
        return "Maximize Conversions";
      default:
        return "Maximize Conversions";
    }
  }

  return (
    <div>
      <Card className={classes.card}>
        <CardContent>
          <Grid container direction="row" rowSpacing={3}>
            <Grid item md={12}>
              <Typography
                color="textPrimary"
                variant={"h3"}
                className={classes.campaignTitle}
                style={{ maxWidth: "auto" }}
              >
                <b>({_.keys(items).length}) Campaign(s)</b>
              </Typography>
            </Grid>
            {_.map(items, (id) => (
              <Grid item sm={12} xs={12} md={12} lg={6}>
                <Paper className={classes.tdPaper}>
                  <UpgradeBiddingStrategyBox
                    taskTypeId={taskTypeId}
                    taskDataById={taskDataById}
                    icon={icons.Campaign}
                    name={taskDataById[id].campaignName}
                    caption={
                      <NewTaskTypeTitle
                        type="Campaign"
                        value={taskDataById[id].campaignType}
                      />
                    }
                    isActivityReport={isActivityReport}
                    id={id}
                    currStrategy={taskDataById[id].customData}
                    recommendedStrategy={getRecommendationStrategy(
                      taskDataById[id]
                    )}
                    targetValue={taskDataById[id].updatedTargetValue}
                    toggleSelection={toggleSelection}
                    targetType={
                      taskTypeId ==
                      TaskTypeEnum.UPGRADE_MANUAL_BID_TO_TARGET_ROAS.taskTypeId
                        ? "TROAS"
                        : "TCPA"
                    }
                    classes={classes}
                  />
                </Paper>
              </Grid>
            ))}
          </Grid>
        </CardContent>
      </Card>
    </div>
  );
}
