import React, { useContext } from "react";
import { content } from "../../NewInterfacePages/help/helpPage";

let id = 0;
function createData(type, name, price, context, category, timePeriod) {
  id += 1;
  return { id, type, name, price, context, category, timePeriod };
}
/* Changing below data might effect the on demand flow */
// let price = 2200;
// let price1 = price.toLocaleString();

// const OnDemandPricingData = JSON.parse(sessionStorage.getItem('onDemandPackages'));
// if (OnDemandPricingData) {
//   console.log("OnDemandPricingData====>", OnDemandPricingData);
// }
export const data = [
  // createData("header", "Services", 0, "", "Services"),
  // createData("item", "Ad Group", 120, "", "Services"),
  // createData("item", "Ad Copy", 20, "", "Services"),
  // createData("header", "Ad Extensions", 0, "", "Ad Extensions"),
  // createData("item", "Sitelink", 10, "", "Ad Extensions"),
  // createData("item", "Price", 10, "", "Ad Extensions"),
  // createData("item", "Structure Snippet", 10, "", "Ad Extensions"),
  // createData("item", "Callout", 5, "", "Ad Extensions"),

  createData("header", "Paid Search", 0, "", "PRO SERVICE PACK", "Days"),
  createData(
    "header",
    "Product Feed Audit",
    0,
    " ",
    "Custom Product Feed Audit/Fix",
    "Hours"
  ),
  createData("header", "Web Development", 0, " ", "Web Development", "Hours"),
  createData("header", "SEO", 0, " ", "SEO Service", "Days"),
  createData(
    "item",
    "Custom Product Feed Audit/Fix",
    500,
    " 5 Hours",
    "Custom Product Feed Audit/Fix",
    "Hours"
  ),
  createData(
    "item",
    "Custom Product Feed Audit/Fix 10",
    1000,
    " 10 Hours",
    "Custom Product Feed Audit/Fix",
    "Hours"
  ),

  createData(
    "item",
    "Web Development",
    1500,
    " 10 Hours",
    "Web Development",
    "Hours"
  ),
  createData("item", "SEO Service", 4000, " 90 Days", "SEO Service", "Days"),
  createData(
    "item",
    "Starter Service Pack",
    500,
    " 7 Days",
    "PRO SERVICE PACK"
  ),
  createData(
    "item",
    "Professional Service Pack",
    800,
    " 15 Days",
    "PRO SERVICE PACK"
  ),
  createData(
    "item",
    "Premier Service Pack",
    2200,
    "30 Days",
    "PRO SERVICE PACK",
    "Days"
  ),
 
];
export const starterServicePack = "Starter Service Pack";
export const professionalServicePack = "Professional Service Pack";
export const premierServicePack = "Premier Service Pack";
export const customProductFeedAuditFix = "Custom Product Feed Audit/Fix";
export const customProductFeedAuditFix10 = "Custom Product Feed Audit/Fix 10";

export const webDevelopment = "Web Development";
export const seoService = "SEO Service";

export const AdGroup = [
  "Want to market a new product line in one of your campaigns? Purchase an ad group, which will contain a set of related keywords and a maximum of 2 ad copies. Use ad groups to organize your ads by a common theme.",
];
export const AdCopy = [
  "If you’re looking to refresh your ads, purchase this pack and our content team will create an ad for you. This ad will come complete with two headlines, a description, and a custom display URL path. Just let our team know what you would like to promote through this ad and we'll get to work!",
];
export const Sitelink = [
  "Sitelinks appear below your ad and link to specific pages within your website, such as a page for an individual product or a related category. Sitelinks add more content to your ad and can help increase your clickthrough rate. You can add sitelinks at the account, campaign, or ad group level.",
];
export const Price = [
  "Price extensions allow you to feature the clickable links to your products or services below your ad shown with their prices. These extensions give searchers a shortcut to purchase the specific product or service they are looking for. Price extensions show off your business’s offerings in an interactive format that users can scroll through.",
];
export const StructuredSnippet = [
  "Structured snippets allow you to feature unique details about your products or services along with one of Google’s 13 available headers. These headers include: styles, types and brands.",
];
export const CallOutExtension = [
  "Callout extensions allow you display 2 to 6 snippets below your ads that highlight any offers or unique selling propositions.",
];
export const StarterServicePackList = [
  "Execute specific tasks on shopping & text ads optimization recommended by LXRGuide Tool.",
  "4 hours of search marketing support.",
];
export const ProfessionalServicePackList = [
  // "Service support through email.",
  // "8 hours of search marketing support.",
  // "Optimization of campaigns for Google and Google Partners Network.",
  // "Review and update geo and language selections.",
  // "Analyze landing page performance.",
  // "Analyze keyword relevancy.",
  // "Analyze existing ad-copy for Google Ads.",
  // "One consultation call per month.",
  "Execute specific tasks on shopping & text ads optimization recommended by LXRGuide Tool.",
  "8 hours of search marketing support.",
];
export const PremierServicePackList = [
  "15 hours of search marketing support.",
  "Analyze ad-extensions.",
  "Inclusion of new keywords through keyword research.",
  "Audit of shopping campaign structure and merchant center feed.",
  "Two consultation calls per month (instead of one).",
];
export const Custom_Product_Feed_Audit_Fix = [
  "Optimize your product feed effortlessly with our Custom Product Feed Audit/Fix solution.",
  "Our tailored approach identifies and rectifies gaps in your feed, ensuring peak performance in shopping and pmax campaigns.",
  "Trust our in-house experts to enhance your feed and drive superior results.",
];
export const Custom_Product_Feed_Audit_Fix_10 = [
  "Optimize your product feed effortlessly with our Custom Product Feed Audit/Fix solution.",
  "Our tailored approach identifies and rectifies gaps in your feed, ensuring peak performance in shopping and pmax campaigns.",
  "Trust our in-house experts to enhance your feed and drive superior results.",
];
export const Web_Development = [
  "Transform your online presence with our Web Development service.",
  "We audit, identify improvements, set expectations, and deliver results—all within 10 hours.",
  "Elevate your website effortlessly with our expert touch.",
];
export const SEO_Service = [
  "Supercharge your online presence with our 3-month SEO Service.",
  "We fix titles, descriptions, add keywords, and conduct a comprehensive website audit for optimal performance.",
  "Elevate your rankings and boost organic traffic efficiently.",
];

export const initialState = {
  currentCounts: {
    // "Ad Group": 0,
    // "Ad Copy": 0,
    // "Ad Extensions": 0,
    // Sitelink: 0,
    // Price: 0,
    // "Structure Snippet": 0,
    // Callout: 0,
    "Starter Service Pack": 0,
    "Professional Service Pack": 0,
    "Premier Service Pack": 0,
    "Custom Product Feed Audit/Fix": 0,
    "Custom Product Feed Audit/Fix 10": 0,
    "Web Development": 0,
    "SEO Service": 0,
  },
  allInputs: {},
  steps: null,
  activeStep: 0,
};
export const Campaigns = [
  {
    value: "Campaign 01",
    label: "Campaign 01",
  },
  {
    value: "Campaign 02",
    label: "Campaign 02",
  },
  {
    value: "Campaign 03",
    label: "Campaign 03",
  },
  {
    value: "Campaign 04",
  },
];
export const selectaplantoSubscribe = [
  "Select a plan to Subscribe",
  "One month of expert paid search service support.",
];
export const selectaplantoSubscribeSEO = [
  "Select a plan to Subscribe",
  "90 days of expert SEO service support.",
];

export const premierServicePackAmount = ["$ 2,200"];
export const seoServicePackAmount = ["$ 4,000"];
export const serviceDescription = [
  "Service Description -",
  "The LXRGuide Premier Service encompasses paid search and shopping (Performance Max) campaign optimization. The scope of work includes:",
];
export const serviceDescriptionSEO = [
  "Quarterly Audits",
  "NetElixir will provide an in-depth site assessment, and solutions and recommendations for issues discovered during the audits, including",
];

export const domainPara = [
  "NetElixir will provide the following Search Engine Optimization services of the domain:",
];

export const premierServicePackText = [
  "Statement of Work for LXRGuide Premier Service",
  'This Statement of Work ("SOW") is entered into between',
  'hereinafter referred to as "Client," and NetElixir, Inc., hereinafter referred to as "NetElixir." This SOW outlines the terms and conditions for the LXRGuide Premier Service provided by NetElixir to the Client.',
  "The LXRGuide Premier Service encompasses paid search and shopping (Performance Max) campaign optimization. The scope of work includes:",
  "Service Duration: The service shall be provided for a period of 30 days from the commencement of work or the kick-off meeting, whichever comes first. The service start and end date shall be communicated over email.",
  "Search Campaign Expansion: This involves strategic enhancements to keywords, ad copies, and ad extensions, including sitelinks, structured snippets, and call outs.",
  "Promotions: Promotions that meet compliance standards of advertising platforms shall be implemented in ad copy text, sitelinks, and/or merchant promotions.",
  "Performance Max Campaign (if applicable): A Performance Max campaign shall be created, subject to the availability of an active product feed in the respective merchant centers. The client shall provide the necessary image and video assets.",
  "Dynamic Search Ads: Dynamic Search Ads campaigns shall be developed based on requirements of the business.",
  "Manual Performance Analysis: Thorough analysis of account performance for optimization purposes shall be carried out including but not limited to search query, geo, day and hour of the week, and device performance analysis.",
  "Ad Copy Writing: All required ad copies shall be written using the proprietary LXRGPT tool, with a provision for 2 iteration requests.",
  "Product Feed Audit: NetElixir shall audit the product feed for shopping or performance max campaigns and provide improvement recommendations.",
  "Monthly Growth Consultation: NetElixir shall schedule a touch point towards the end of the service period to discuss the account performance, additional recommendations, and other channel opportunities. Some of the additional services discussed during the consultation may require separate agreements and additional payments.",
  "Exclusions -",
  "The following services are not covered under this SOW:",
  "Display, Discovery, YouTube (Video), and Mobile App campaign creation.",
  "Creation or implementation of Google product feeds.",
  "Google Ads / Microsoft Ads / Google Ads conversion tracking setup.",
  "GA4 implementation, event creation, and data layer adjustments.",
  "Creation or sharing of custom reports, except for optimizations identified by NetElixir.",
  "Prerequisites -",
  "The Client agrees to provide the following prerequisites to facilitate the LXRGuide Premier Service:",
  "Grant access to Google Ads, Microsoft Ads, and Google Analytics accounts as applicable.",
  "Provide access to respective merchant center accounts. ",
  "Ensure accurate implementation of account conversion and revenue tracking.",
  "Provide a copy of recent product feed in a readily accessible spreadsheet format, particularly if the upload method involves the Content API.",
  "Share a promotion calendar limited to the service period.",
  "Recurring Service Option -",
  "Should the Client opt for a recurring LXRGuide Premier Service, the following terms shall apply:",
  "The LXRGuide Premier Service, as described in this Statement of Work, shall be provided as a continuous service beyond the initial 30-day period. The terms and conditions of this SOW shall remain applicable unless and until the Client decides to cancel the service.",
  "In the event of continued service, the Client acknowledges and agrees that the amount corresponding to the SEM Premier Service shall be automatically billed to the existing payment method on file every 30 days.",
  "By clicking the accept button below, the client acknowledges their understanding and agreement to the terms set forth in this SOW.",
  " * You will be charged till you cancel it from payment page.",
];
export const seoServicePackText = [
  "Statement of Work for LXRGuide SEO Service",
  'This Statement of Work ("SOW") is entered into between',
  'hereinafter referred to as "Client," and NetElixir, Inc., hereinafter referred to as "NetElixir." This SOW outlines the terms and conditions for the LXRGuide SEO Service provided by NetElixir to the Client.',
  "Site Architecture Audit",
  "An in-depth review of the site from a technical perspective to ensure proper crawlability as well as ideal indexation, with detailed technical recommendations",
  "Sitemap audit",
  "Robots.txt file audit",
  "Search Console audits",
  "SERP audit",
  "Backlink and Disavow Report:",
  "An audit of the existing links to make sure link profile is organic and doesn’t include spammy links",
  "Mobile First Audit",
  "Consultation on optimizing mobile or smartphone strategy in relation to SEO best practices",
  "Page speed performance audit of the home page, category and product pages and recommendations to optimize the performance.",
  "Semantic Markup Recommendations",
  "Structured data recommendation, when applicable, to acquire rich results.",
  "Communication",
  "Call time of up to 1 hour will be available to discuss audits/strategies once every quarter. Additional call time will incur fees.",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
];

// export default function OndemandPackageBackend(onDemandPackages){
//   console.log("OndemandPackageBackend ====>",onDemandPackages);
// }
